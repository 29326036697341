import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Switch,
  Typography,
  Upload,
  message,
} from "antd";
import axios from "axios";
import moment from "moment";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useContext, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import { uploadFile } from "react-s3";
import Prouser from "../../assets/images/user.png";
import AccountSideNav from "../../components/AccountSideNav";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import apiPath from "../../constants/apiPath";
import { useAppContext } from "../../context/AppContext";
import { AuthContext } from "../../context/AuthContext";
import { dateString } from "../../helper/functions";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import InvitefrndImg from "../../assets/images/invite-frnd.png";
import Main from "../../components/layout/Main";
import Loader from "../../components/Loader";
import lang from "../../helper/langHelper";

window.Buffer = window.Buffer || require("buffer").Buffer;

function About() {
  const { request } = useRequest();

  const { isMobile ,language } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
 
  const fetchData = () => {
    setLoading(true)
    request({
      url: apiPath.baseURL + "/app/content/about-us",
      method: "GET",
      onSuccess: (data) => {
        console.log("data", data);
        if (!data.status) return ShowToast(data?.message, Severty.ERROR);
        setData(data?.docs?.[0])
        setLoading(false)
      },
      onError: (err) => {
        setLoading(false)
        console.log(err);
        ShowToast(err, Severty.ERROR);
      },
    });
  };
  

useEffect(()=>{
  setLoading(true)
  fetchData()
},[])

  return (
    <>
      <Main>
        <section className="main">
          <div className="account-outers">
            <Row>
              {!isMobile ? <AccountSideNav /> : <BackArrow />}
              <Col span={24}  lg={18} xl={15} className="mx-auto  amt-auto">
                <div className="about-us-maain">
                  <h3>{lang("About Us")}</h3>
                 {loading ? <Loader/> : <div className="about-us-discripton">       
                    <p>
                    { language !== "en" && language !== null
                     ? data?.[`${language}_description`] ?? data?.description
                     : data?.description }
                    </p>

                  </div>}
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </Main>
    </>
  );
}

export const BackArrow = ({}) => {
  const navigate = useNavigate();
  return (
    <div className="fillter-sidebar">
      <Link onClick={() => navigate(-1)} className="sidebar-btn">
        <ArrowLeftOutlined />
      </Link>
    </div>
  );
};

export default About;
