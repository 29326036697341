import { Layout, Row, Col, Form, Input, Button, message } from "antd";
import { HeartFilled } from "@ant-design/icons";
import AndroidDownloadImg from "../../assets/images/android_download (1).png";
import IosDownloadImg from "../../assets/images/ios_download (1).png";
import CopyrightImg from "../../assets/images/copyright.png";
import TwitterImg from "../../assets/images/twitter.png";
import FacebookImg from "../../assets/images/facebook.png";
import InstagramImg from "../../assets/images/instagram 2.png";
import YoutubeImg from "../../assets/images/youtube.png";
import LogoImg from "../../assets/images/logo img.png";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import { useAuthContext } from "../../context/AuthContext";
import lang from "../../helper/langHelper";
import { Link, useNavigate } from "react-router-dom";
import { useRef } from "react";


function Footer() {
  const { Footer: AntFooter } = Layout;
  const { request } = useRequest();
  const [form] = Form.useForm()
  const { userProfile, isLoggedIn } = useAuthContext()
  const navigate = useNavigate();

  const targetcontact = useRef(null);
  const targetChoose = useRef(null);

  const smoothScroll = (target) => {
    target.scrollIntoView({ behavior: "smooth" });
  };

  const handleScrollContact = () => {
    if (targetcontact.current) {
      smoothScroll(targetcontact.current);
    }
  };

  const handleScrollChoose = () => {
    if (targetChoose.current) {
      smoothScroll(targetChoose.current);
    }
  };

  const onSubscribe = (values) => {
    const payload = { ...values }

    request({
      url: `${apiPath.subscribe}`,
      method: "Post",
      data: payload,
      onSuccess: (data) => {
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS)
          form.resetFields()
        } else {
          ShowToast(data.message, Severty.ERROR)
        }
        console.log(data, "onSubscribe")
      },
      onError: (err) => {
        ShowToast(err?.response?.data?.message, Severty.ERROR)
        console.log(err);
      }
    })
  }

  return (
    <section className="bg-img3">
      <div className="container for-width">
        <div className="for-border">
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <div className="for-fllx">
                <div className="logo-img4">
                  <img src={LogoImg} alt="#" />
                </div>
                <p>
                  {lang("Transforming your vision into unforgettable events.")}{" "}
                  {lang("Your trusted partner for seamless and memorable celebrations.")}
                </p>
                <h3>{lang("Download App")}</h3>
                <div className="logo-android main-play-logo">
                  <div onClick={() => window.open("https://play.google.com/store/apps?", "_blank")}>
                    <a href="">
                      <img src={AndroidDownloadImg} />
                    </a>
                  </div>
                  <div onClick={() => window.open("https://www.apple.com/in/app-store/", "_blank")}>
                    <a href="">
                      <img src={IosDownloadImg} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-12">          
              <ul className="list2 for-res">  
               <li> <h1>{lang("Pages")}</h1> </li>
                <li>
                  <Link  to={"/login"}  className="nav-link" >  {lang("Home")}  </Link>
                  <Link  to={"/aboutUs"}  className="nav-link"    >  {lang("About us")} </Link>
                  <Link  to={"/home-gallary"} onClick={() => navigate("/home-gallary")}  className="nav-link"  >{lang("Gallery")}  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-4 col-sm-12">
              <ul className="list2 subscribe-1">
                <li className=""> <h1>{lang("Subscribe")}</h1>   </li>
                <li> <p className="mt-2">  {lang("Unlock exclusive benefits and enjoy hassle-free transportation with our Planit Application Subscription")}  </p>  </li>
                <li>
                  <Form  form={form}  onFinish={onSubscribe}  >
                    <div className="gmail-box-1">
                      <Form.Item
                        name="email"
                      rules={[
                        {
                          type: 'email',
                          message: 'The input is not valid E-mail!',
                        },
                        {
                          required: true,
                          message: 'Please input your E-mail!',
                        },
                      ]}
                      >
                        <Input placeholder={lang("Enter Email")} />
                      </Form.Item>
                      <Button htmlType="submit">
                        {lang("Subscribe")}
                      </Button>
                    </div>
                  </Form>


                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="copy">
              <img src={CopyrightImg} alt="#" />
              {lang("Copyright 2024, All Rights Reserved by Event Management")}
            </div>
          </div>
          <div className="col-md-4">
            <ul className="list3">
              {/* <img src={TwitterImg} onClick={() => window.open("https://x.com/?lang=en", "_blank")} /> */}
              <img src={FacebookImg} onClick={() => window.open("https://www.facebook.com/", "_blank")} />
              <img src={InstagramImg} onClick={() => window.open("https://www.instagram.com/app.plan.it?igsh=MWQ4am02ZGdzcDQ2Zg==", "_blank")} />
              {/* <img src={YoutubeImg} onClick={() => window.open("https://www.youtube.com/", "_blank")} /> */}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
