import { Col, Row, Form, Modal, Input, Button } from "antd";
import Stories from "react-insta-stories";
import { formatStringDate, formatTimeAgo } from "../helper/functions";
import { useAuthContext } from "../context/AuthContext";
import lang from "../helper/langHelper";
import { useState } from "react";
import {
  HeartOutlined,
  SendOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import ChatRepository from "../pages/Message/ChatRepository";
import { serverTimestamp } from "firebase/firestore";
import { Severty, ShowToast } from "../helper/toast";

const ReadStory = ({ show, hide, refresh, data, providerData }) => {
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const chatRepo = ChatRepository();

  const stories = data?.map((story) => ({
    url: story?.image,
    duration: 5000,
    header: {
      heading: story?.title,
      subheading: `Posted ${formatTimeAgo(story?.created_at)}`,
      profileImage: providerData?.logo || "https://picsum.photos/100/100",
    },
  }));

  const sendMessage = () => {
    if (msg && msg?.trim()) {
      let userId = JSON.parse(localStorage.getItem("userProfile"))?._id;

      const groupdId = userId + "_" + providerData?._id;

      let oppositeUserData = {
        image: providerData?.logo,
        mobileNumber: providerData?.mobile_number,
        name: providerData?.name,
        unseenCount: 0,
      };
      let quotationData = {
        title: "",
        image: "",
        budget: "",
        description: "",
        isQuotation: false,
      };

      chatRepo.createGroup(
        groupdId,
        userId,
        providerData?._id,
        oppositeUserData,
        quotationData
      );

      const message = {
        groupId: groupdId,
        message: msg,
        sendTime: serverTimestamp(),
        senderId: userId,
        type: "text",
      };

      console.log(message, " : : message");
      chatRepo.updateSeenMessages(groupdId, userId, msg);
      chatRepo.sendMessage(message);
      ShowToast("Message Sent!", Severty.SUCCESS);
      setMsg("");
    }
  };

  return (
    <Modal
      open={show}
      cancelText={<ArrowRightOutlined className="main-back--button-csss" />}
      width={360}
      onCancel={hide}
      centered
      okButtonProps={{
        form: "story",
        htmlType: "submit",
        loading: loading,
      }}
      className="full-width-modal tab_modal deleteWarningModal eleteWarningModal-hhht5657"
    >
      <Form className="" id="story" layout="vertical">
        <Row gutter={[16, 0]} className="stories-container">
          <Col span={24} sm={24} className="mx-auto p-0">
            <Stories
              stories={stories}
              defaultInterval={3000}
              width={"100%"}
              className={("stories", "new-class")}
            />
            <div className="message-container">
              <Input
                placeholder="Send Message"
                value={msg}
                onChange={(e) => setMsg(e.target.value)}
                className="message-input"
              />

              <Button
                shape="circle"
                onClick={() => {
                  sendMessage();
                }}
                icon={<SendOutlined />}
                className="icon-button"
              />
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ReadStory;
