import {
  Home,
  Auth,
  Provider,
  RequestQuote,
  ViewQuotation,
  Message,
  Profile,
  Service,
  Account,
} from "./pages/Index";

import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { AppContextProvider } from "./context/AppContext";
import React, { Suspense, useState } from "react";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import ScrollToTop from "./components/ScrollToTop";
import Loader from "./components/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Chat from "./pages/Message/Chat";
import EventList from "./pages/Event/EventList";
import EventBudget from "./pages/Event/BudgetList";
import Noactive from "./pages/Noactiveevent";
import Favourite from "./pages/Account/Favourite";
import ChangePassword from "./pages/Account/ChangePassword";
import Invite from "./pages/Account/Invite";
import About from "./pages/Account/AboutUs";
import Privacy from "./pages/Account/PrivacyPolicy";
import RateOnStore from "./pages/Account/RateOnStore";
import Blog from "./pages/blogpage";
import Blogdetails from "./pages/blogpagedetails";
import Header from "./components/layout/Header";
import ProviderRating from "./pages/Provider/Ratings";
import AllProvider from "./pages/Provider/AllProviders";
import AddBudgetServices from "./pages/Event/AddBudgetServices";
import AddBudgetCategory from "./pages/Event/AddBudgetCategory";
import PrivateRoute from "./components/PrivateRoute";
import ServiceByEvent from "./pages/ServiceByEvent/ServiceByEvent";
import Collab from "./pages/Collab";
import AboutUsPage from "./pages/Auth/AboutUs";
import HomeGallary from "./pages/Auth/HomeGallary";

<link
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"
  rel="stylesheet"
/>;

window.Buffer = window.Buffer || require("buffer").Buffer;
function App() {
  return (
    <AuthProvider>
      <AppContextProvider>
        <Suspense fallback={<Loader />}>
          <BrowserRouter>
            <ScrollToTop />
            <ToastContainer closeOnClick={false} />
            <AppRoutes />
          </BrowserRouter>
        </Suspense>
      </AppContextProvider>
    </AuthProvider>
  );
}

const AppRoutes = () => {
  const [query, setQuery] = useState("");

  return (
    <>
      {" "}
      {<Header query={query} setQuery={setQuery} />}
      <Routes>
        <Route path="/login" element={<Auth />} />
        <Route path="/signup" element={<Auth />} />
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/service" element={<Service />} />
        <Route path="/service-by-event/:id" element={<ServiceByEvent />} />
        <Route path="/service/:id" element={<Service />} />
        {/* Provider */}
        <Route path="/all-providers" element={<AllProvider />} />
        <Route path="/provider/:id" element={<Provider />} />
           {/* // if does not login About */}
        <Route path="/aboutUs" element={<AboutUsPage />} />      
        <Route path="/home-gallary" element={<HomeGallary />} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Layout />
            </PrivateRoute>
          }
        >
          <Route path="/request-quote/:id" element={<RequestQuote />} />
          <Route path="/quotation" element={<ViewQuotation />} />
          <Route path="/notifications" element={<Message />} />
          <Route path="/chat" element={<Chat />} />

          <Route path="/provider-rating/:id" element={<ProviderRating />} />

          {/* Event Router */}
          <Route path="/event-list" element={<EventList />} />
          <Route path="/event-list/:id" element={<EventList />} />
          <Route path="/event-budget/:id" element={<EventBudget />} />
          <Route
            path="/event-budget/addcategory/:id"
            element={<AddBudgetCategory />}
          />
          <Route
            path="/event-budget/addServices/:categoryId/:eventId"
            element={<AddBudgetServices />}
          />

          <Route path="/noactive" element={<Noactive />} />

          {/* //Account Menu ----> */}
          <Route path="/account" element={<Account />} />
          <Route path="/favourite" element={<Favourite />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/invite" element={<Invite />} />
          {/* //Account Side Nav About */}
          <Route path="/about-us" element={<About />} />  
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/rate-store" element={<RateOnStore />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blogdetail" element={<Blogdetails />} />
          <Route path="/collab" element={<Collab />} />
       
        </Route>
      </Routes>
    </>
  );
};

const Layout = () => {
  return (
    <>
      {" "}
      <Outlet />{" "}
    </>
  );
};

export default App;
