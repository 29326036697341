import React, { useState, useEffect, useRef } from "react";
import {
  Tabs,
  Modal,
  Button,
  Input,
  Form,
  Col,
  Row,
  Checkbox,
  Space,
} from "antd";
import OwlCarousel from "react-owl-carousel3";
import { Navbar, Nav, Container } from "react-bootstrap";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Carousel from "react-bootstrap/Carousel";
import Main from "../../components/layout/Main";
import "bootstrap/dist/css/bootstrap.min.css";
import { Accordion } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";

import LogoImg from "../../assets/images/logo img.png";
import EmailImg from "../../assets/images/email.png";
import AndroidDownloadImg from "../../assets/images/android_download (1).png";
import IosDownloadImg from "../../assets/images/ios_download (1).png";
import BusinessWomImg from "../../assets/images/businesswoman-showing-her-smartphone-app-taxi-driver 1.png";
import CheckImg from "../../assets/images/check-circle.png";
import Newphone1Img from "../../assets/images/new phone img 2 .png";
import MyntmobImg from "../../assets/images/mynt-mob1.png";
import Groth1Img from "../../assets/images/groth1.png";
import Groth2Img from "../../assets/images/excellince.png";
import Groth3Img from "../../assets/images/gobally.png";
import PlanitmainImg from "../../assets/images/planit img.png";
import Whyus1Img from "../../assets/images/Rectangle 4248.png";
import Whyus2Img from "../../assets/images/iconoir_quote-solid.png";
import Modelimage1Img from "../../assets/images/model-img.png";
import Login from "./Login";
import SignUp from "./SignUp";
import { useAuthContext } from "../../context/AuthContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import { PhoneNumberField } from "../../components/InputField";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import Prouser from "../../assets/images/user.png";
import { useAppContext } from "../../context/AppContext";
import moment from "moment";

const responsiveSetting = {
  0: {
    items: 1,
  },
  600: {
    items: 1.2,
  },
  1000: {
    items: 2,
  },
  1200: {
    items: 2,
  },
  1400: {
    items: 2,
  },
};

function LandingIndex() {
  const navigate = useNavigate();
  const [showlogin, setShowLogin] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const { userProfile, isAdmin ,isLoggedIn } = useAuthContext();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const targetcontact = useRef(null);
  const targetChoose = useRef(null);

  const smoothScroll = (target) => {
    target.scrollIntoView({ behavior: "smooth" });
  };

  const handleScrollContact = () => {
    if (targetcontact.current) {
      smoothScroll(targetcontact.current);
    }
  };

  const handleScrollChoose = () => {
    if (targetChoose.current) {
      smoothScroll(targetChoose.current);
    }
  };





  

  return (
    <Main>
      <>
        <div className="header extra">
          <Navbar
            bg=""
            expand="lg"
            className="navbar navbar-expand-lg navbar-light"
          >
            <Container>
              <Navbar.Brand onClick={() => navigate("/")}>
                <img src={LogoImg} alt="Logo" className="home-logo" style={{cursor : "pointer"}} />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarSupportedContent" />
              <Navbar.Collapse id="navbarSupportedContent">
                <Nav className="ms-auto mb-2 mb-lg-0">
                  <Nav.Link onClick={() => navigate("/login")}       className="nav-link active" >{lang("Home")}</Nav.Link>
                  <Nav.Link onClick={() => navigate("/aboutUs")}      className="nav-link" >       {lang("About us")}</Nav.Link>
                  <Nav.Link onClick={handleScrollChoose}              className="nav-link">        {lang("Why Choose")}</Nav.Link>
                  <Nav.Link onClick={() => navigate("/home-gallary")} className="nav-link"  >      {lang("Gallery")}  </Nav.Link>
                  <Nav.Link onClick={handleScrollContact}             className="nav-link">        {lang("Contact Us")} </Nav.Link>
                  {!isLoggedIn ? 
                  <Nav.Item className="header-button-main1">
                    <Button  variant="primary"   onClick={() => setShowLogin(true)}   className="btn-first" >    {lang("Login")}</Button>
                    <Button  variant="secondary" onClick={() => setShowSignUp(true)}  className="btn-first-1" >  {lang("Sign Up")}</Button>
                  </Nav.Item> : "" }
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <section className="main-hero-bg">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 col-lg-6">
                <div className="left-bar-slaide">
                  <h6>
                    {lang("EventMaster: Your Ultimate Event Management Solution")}
                  </h6>
                  <h2>
                    {lang("Donec vitae mi vulputate, suscipit urna in, malesuada nisl. Pellentesque pretium, Donec vitae Pellentesque pretium,")}
                  </h2>
                  <div className="slaider-btn">
                    <div className="logo-android">
                      <div><a href=""><img src={AndroidDownloadImg} /></a> </div>
                      <div><a href=""><img src={IosDownloadImg} /> </a></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="right-bar-slide">
                  <img src={PlanitmainImg} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="background-main-color">
          <div className="container">
            <div className="groth-main">
              <div className="groth" id="groth-2">
                <div>
                  <img src={Groth1Img} alt="#" />
                </div>
                <h2>90%</h2>
                <h4>{lang("Success Rate")}</h4>
              </div>
              <div className="groth" id="groth-2">
                <div>
                  <img src={Groth2Img} alt="#" />
                </div>
                <h2>90%</h2>
                <h4>{lang("Success Rate")}</h4>
              </div>
              <div className="groth">
                <div>
                  <img src={Groth3Img} alt="#" />
                </div>
                <h2>90%</h2>
                <h4>{lang("Success Rate")}</h4>
              </div>
            </div>
          </div>
        </section>
        <section className="download-bg">
          <div className="container for-width">
            <div className="row">
              <div className="col-md-6 col-lg-6">
                <div className="download-page">
                  <h3>
                    {lang("Overall 400k+ Over User Please get Download Now")}{" "}
                  </h3>
                  <p>
                   {lang("Discover why over 400,000 users have chosen our app to enhance their event experiences. Our platform offers seamless event management, ensuring that every detail is perfectly executed. Whether you're planning a corporate gathering or a personal celebration, our app is your go-to solution for all your event needs.")}
                  </p>
                  <div className="slaider-btn">
                    <div className="logo-android">
                      <div>
                        <a href="">
                          <img src={AndroidDownloadImg} />
                        </a>
                      </div>
                      <div>
                        <a href="">
                          <img src={IosDownloadImg} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="phone-img-inner">
                  <img src={MyntmobImg} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="choose-us" id="choose" ref={targetChoose}>
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <div className="book-left ">
                  <img src={Newphone1Img} />
                </div>
              </div>
              <div className="col-md-12 col-lg-6 ">
                <div className="book-right">
                  <div className="headding">
                    <h4>{lang("Why Choose us ?")}</h4>
                    <div className="bar" />
                    <span>
                      {lang(
                        "With years of experience in event management, our team knows how to bring your vision to life. From corporate events to personal celebrations, we handle every detail with precision."
                      )}
                    </span>
                  </div>
                  <div className="booking-list">
                    <ul>
                      <li>
                        <span>
                          <img src={CheckImg} alt="#" />
                        </span>
                        <div>
                          <h2>{lang("Tailored Solutions")}</h2>
                          <p>
                            {lang(
                              "We understand that every event is unique. That's why we offer customized packages tailored to meet your specific needs, ensuring your event is exactly what you envisioned."
                            )}
                          </p>
                        </div>
                      </li>
                      <li>
                        <span>
                          <img src={CheckImg} alt="#" />
                        </span>
                        <div>
                          <h2>{lang("Creative Excellence")}</h2>
                          <p>
                            {lang("Our innovative approach and creative flair make your events stand out. We bring fresh ideas to the table, making each event distinctive and engaging.")}
                          </p>
                        </div>
                      </li>
                      <li>
                        <span>
                          <img src={CheckImg} alt="#" />
                        </span>
                        <div>
                          <h2>{lang("Reliable Partnerships")}</h2>
                          <p>
                           {lang("We collaborate with trusted vendors and suppliers, ensuring that every aspect of your event, from catering to decor, meets the highest standards of quality.")}
                          </p>
                        </div>
                      </li>
                      <li>
                        <span>
                          <img src={CheckImg} alt="#" />
                        </span>
                        <div>
                          <h2>{lang("Seamless Execution")}</h2>
                          <p>
                           {lang("Our meticulous planning and attention to detail guarantee that your event runs smoothly from start to finish, allowing you to enjoy the moment without any worries.")}
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  {/* <button className="btn-first" id="button-main-3">
                    Book an Appointment
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="testimonial-sec">
          <div className="container">
            <div className="teext2">
              <h4>{lang("Happy client says")}</h4>
              <div className="bar" id="bar-1" />
              <p>
                {lang("We take pride in delivering exceptional service. Read the testimonials from our clients who have trusted us with their events.")}
              </p>
            </div>
            <Testimonial />
          </div>
        </section>
        <section id="contactus" className="bg-img2" ref={targetcontact}>
          <div className="container for-width">
            <div className="from-main">
              <div className="businesswomenimg">
                <img src={BusinessWomImg} />
              </div>
              <div className="form-11">
                <div className="tophead">
                  <h3>{lang("Get in touch")}</h3>
                  <div className="bar" />
                  <p>
                    {lang("Our friendly team would love to hear from you.")}
                  </p>
                </div>
                <div className="containerni">
                  <SupportSection />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="faq-section">
        <FaqSection/>
        </section>
        {/* <section className="bg-img2">
          <Subscribe />
        </section> */}

        <Login   show={showlogin}   hide={() => setShowLogin(false)} />
        <SignUp  show={showSignUp}  hide={() => setShowSignUp(false)} login={() => { setShowLogin(true);   setShowSignUp(false); }}
        />

      

       
      </>
    </Main>
  );
}

export default LandingIndex;



const FaqSection = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { request } = useRequest();
  const [form] = Form.useForm();
  const { language } = useAppContext();

  const fetchData = () => {
    setLoading(true)
    request({
      url: apiPath.baseURL + "/app/content/about-us",
      method: "GET",
      onSuccess: (data) => {
        console.log("data", data);
        if (!data.status) return ShowToast(data?.message, Severty.ERROR);
        setData(data?.docs?.[0])
        setLoading(false)
      },
      onError: (err) => {
        setLoading(false)
        console.log(err);
        ShowToast(err, Severty.ERROR);
      },
    });
  };
  

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
     <div className="container for-width">
            <div className="row">
              <div className="">
                <div className="headding text-center">
                  <h4>Frequently asked questions</h4>
                  <div className="bar" id="bar-1" />
                  <span>
                    Everything you need to know about the product and billing.
                  </span>
                </div>
                {data ? data?.faq?.map((item)=>(
                  <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="freq-ask-qqq">
                     {item?.question}
                    </Accordion.Header>
                    <Accordion.Body>
                    {item?.answer}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                )) : 
                <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="freq-ask-qqq">
                    Is there a free trial available?
                  </Accordion.Header>
                  <Accordion.Body>
                    Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
                }
              
                

                <div>
                  <div></div>
                </div>
              </div>
            </div>
          </div> 
    </>
  );


};

const Testimonial = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { request } = useRequest();
  const [form] = Form.useForm();
  const { language } = useAppContext();

  const fetch = () => {
    setLoading(true);
    request({
      url: apiPath.testimonial,
      method: "GET",
      onSuccess: ({ data }) => {
        if (data.docs) {
          console.log(data, "data...");
          setData(data.docs ?? []);
        }
        setLoading(false);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error?.response?.data?.message, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <>
      <OwlCarousel
        items="2"
        className="owl-theme"
        loop={false}
        margin={10}
        nav={false}
        dots={true}
        responsive={responsiveSetting}
      >
        {data?.length && data.length > 0
          ? data?.map((item) => (
              <div className="item">
                <div className="testimonial-wrap">
                  <div className="main-img">
                    <h5>
                      <img src={Whyus2Img} alt="#" />
                    </h5>
                    <p>
                      {language !== "en" && language !== null
                        ? item?.[`${language}_name`] ?? item?.name
                        : item?.name}
                    </p>
                    <div className="author-wrap">
                      <h4
                        dangerouslySetInnerHTML={{
                          __html:
                            language !== "en" && language !== null
                              ? item?.[`${language}_description`] ??
                                item?.description
                              : item?.description,
                        }}
                      />

                      <h6>
                        {item.created_at
                          ? moment(item.created_at).format("ll")
                          : ""}
                      </h6>
                    </div>
                  </div>
                  <div className="main-img2">
                    <img src={item?.image ?? Prouser} alt="" />
                  </div>
                </div>
              </div>
            ))
          : ""}
      </OwlCarousel>
    </>
  );
};



const SupportSection = () => {
  const [loading, setLoading] = useState(false);
  const [mobileNumber, setMobileNumber] = useState(null);
  const { request } = useRequest();
  const [form] = Form.useForm();

  const handleMobileNumberChange = (value, data, type) => {
    let country_code = data?.dialCode;
    setMobileNumber({
      country_code: country_code,
      mobile_number: value.slice(data?.dialCode?.length),
    });
  };

  const onSubmit = (values) => {
    setLoading(true);
    let payload = { ...values, ...mobileNumber };

    request({
      url: apiPath.support,
      method: "POST",
      data: { ...payload },
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          form.resetFields();
          ShowToast(data.message, Severty.SUCCESS);
        } else {
          setLoading(false);
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error?.response?.data?.message, Severty.ERROR);
      },
    });
  };

  return (
    <Form
      form={form}
      onFinish={(e) => onSubmit(e)}
      layout="vertical"
      className="row-col signup5-model"
    >
      <Row gutter={24}>
        <Col span={24} md={12}>
          <Form.Item
            className="username"
            name="first_name"
            rules={[
              {
                max: 250,
                message: lang("Name should contain more then 250 characters!"),
              },
              {
                required: true,
                message: lang("Please Enter  Name"),
              },
            ]}
          >
            <Input placeholder={lang("Enter First Name")} />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            className="username"
            name="last_name"
            rules={[
              {
                max: 250,
                message: lang("Name should contain more then 250 characters!"),
              },
              {
                required: true,
                message: lang("Please Enter  Name"),
              },
            ]}
          >
            <Input placeholder={lang("Enter Last Name")} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        className="username"
        // label={lang("Email Address")}
        name="email"
        rules={[
          {
            type: "email",
            message: lang("Please enter a valid email address!"),
          },
          {
            max: 255,
            message: lang("Email address not more then 255 characters!"),
          },
          {
            required: true,
            message: lang("Please enter email!"),
          },
        ]}
      >
        <Input placeholder={lang("Enter Email address")} />
      </Form.Item>

      <PhoneNumberField
        label={""}
        name="mobile"
        placeholder={lang("Enter Phone Number")}
        cover={{ md: 24 }}
        colProps={{ sm: 24, span: 24 }}
        className="new-mobile-passwordnew username"
        onChange={handleMobileNumberChange}
        number={mobileNumber?.mobile_number}
      />

      <Col span={24} md={24}>
        <Form.Item
          className="username new-text-area-main"
          // label={lang("Last Name")}
          name="message"
          rules={[
            {
              max: 1000,
              message: lang(
                "Message should contain more then 1000 characters!"
              ),
            },
            {
              required: true,
              message: lang("Please Enter  Message"),
            },
          ]}
        >
          <Input.TextArea
            maxLength={10}
            minLength={5}
            placeholder={lang("Enter Your Message")}
          />
        </Form.Item>
      </Col>

      <Col md={24}>
        <Form.Item
          name="agree"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) => {
                if (value !== undefined && value === true) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    lang(
                      "Please confirm  agree to our friendly privacy policy."
                    )
                  )
                );
              },
            },
          ]}
        >
          <Space align="baseline">
            <Checkbox
            //defaultChecked={true}
            >
              {lang("You agree to our friendly privacy policy.")}
            </Checkbox>
          </Space>
        </Form.Item>
      </Col>

      <Form.Item>
        <Button
          className="float-right float-right-main-new-send-button"
          type="primary"
          htmlType="submit"

          // loading={loading}
        >
          {lang("Send")}
        </Button>
      </Form.Item>
    </Form>
  );
};
