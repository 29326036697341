import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from '../context/AuthContext'; // Assuming you have a UserContext to get user data
import apiPath from "../constants/apiPath";
import useRequest from "../hooks/useRequest";



const Commonfunction = () => {
    const { userProfile } = useAuthContext();
    const [user, setUser] = useState();
    const { request } = useRequest();
     const navigate = useNavigate();



    const updateProviderViewCount = (id) => {
        let vendor_id = id ? id : "";
        request({
          url: `${apiPath.updateViewCount}/${vendor_id}`, // Pass vendor_id in the URL
          method: "GET",
          onSuccess: (data) => {
            if (data.status) {
              console.log(data?.message,"updateviewCount")
              navigate(`/provider/${vendor_id}`)
            }
          },
          onError: (err) => {
            // ShowToast(err, Severty.ERROR);
            console.log(err);
          },
        });
      };

  


    return {
      
        updateProviderViewCount
    };
};

export default Commonfunction;
