import { Form ,Input,Button} from "antd";
import GroupImg from "../assets/images/Group.png";
import EmailImg from "../assets/images/email.png";
import useRequest from "../hooks/useRequest";
import apiPath from "../constants/apiPath";
import { useForm } from "antd/lib/form/Form";
import { Severty, ShowToast } from "../helper/toast";
import { useNavigate } from "react-router";
import { useAuthContext } from "../context/AuthContext";
import lang from "../helper/langHelper"

const InviteFriends = () => {

  const { request } = useRequest();
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const {isLoggedIn} = useAuthContext()


  const onSubscribe = (values) => {
    const payload = {...values}
    
    request({
      url: `${apiPath.subscribe}`,
      method: "Post",
      data : payload,
      onSuccess: (data) => {
        if (!data.status) return;
        ShowToast(data.message,Severty.SUCCESS)
        form.resetFields()
        console.log(data, "onSubscribe")
      },
      onError: (err) => {
        ShowToast(err,Severty.SUCCESS)
        console.log(err);
      }
    })
  }

  const handleNavigate = ()=>{
    if(!isLoggedIn) return navigate("/login")
      navigate("/invite")
  }
  return (
    <>
      <section className="invt_bg ">
        <div className="container">
          <div className="invt-frd">
            <div className="row">
              <div className="col-md-7">
                <div className="invt-text">
                  <h5>{lang("Invite Friends")}</h5>
                  <p>
                  {lang("Invite 10 New friends to join our app and get 20 Loyalty Points to use in amazing rewards!")}
                  </p>
                  <button onClick={()=> handleNavigate()}>
                    <a >{lang("Invite Friends")}</a>
                  </button>
                </div>
              </div>
              <div className="col-md-5">
                <div className="invt-img">
                  <img src={GroupImg} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="bg-img2 home-p">
        <div className="container">
          <div className="mail-box">
            <div>
              <h4>{lang("Subscribe To Our Newsletter")}</h4>
            </div>
            <div>
              <p>{lang("Get update information about update and get latest news")}</p>
            </div>
            <Form form={form} onFinish={onSubscribe}>
            <div className="gmail-box">
              <img src={EmailImg} alt="Email Icon" />
              <Form.Item
                name="email"
              >
                <Input type="text" placeholder={lang("Enter your email address")} />
              </Form.Item>
              <Button htmlType="submit">
                {lang("Subscribe")}
              </Button>
            </div>
          </Form>
         
          </div>
        </div>
      </section> */}
    </>
  );
};

export default InviteFriends;
