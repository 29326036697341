import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Result, Button } from "antd";
import useRequest from "../hooks/useRequest";
import apiPath from "../constants/apiPath";

const Collab = () => {
  const { request } = useRequest();
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const handlePage = () => {
    request({
      url: apiPath.eventGuestDetail,
      method: "POST",
      onSuccess: ({ data }) => {
        navigate("/event-list");
        getEvents();
        setData(data);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const getEvents = () => {
    request({
      url: apiPath.eventList,
      method: "GET",
      onSuccess: ({ data }) => {
        setData(data);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  return (
    <Result
      status="success"
      title="Thank you for collaborating!"
      subTitle="We appreciate your collaboration."
      extra={[
        <Button type="primary" key="home" onClick={handlePage}>
          Back Home
        </Button>,
      ]}
    />
  );
};

export default Collab;
