import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Switch,
  Typography,
  Upload,
  message,
} from "antd";
import axios from "axios";
import moment from "moment";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useContext, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import { uploadFile } from "react-s3";
import Prouser from "../../assets/images/user.png";
import AccountSideNav from "../../components/AccountSideNav";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import apiPath from "../../constants/apiPath";
import { useAppContext } from "../../context/AppContext";
import { AuthContext } from "../../context/AuthContext";
import { dateString } from "../../helper/functions";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import InvitefrndImg from "../../assets/images/invite-frnd.png";
import { PhoneNumberField } from "../../components/InputField";
import lang from "../../helper/langHelper";
import Loader from "../../components/Loader";

window.Buffer = window.Buffer || require("buffer").Buffer;

function Invite() {
  const { request } = useRequest();

  const { isMobile } = useAppContext();
  const { setIsLoggedIn, setUserProfile, userProfile } =
    useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [form] = Form.useForm();

  const onSubmit = (values) => {
    setLoading(true);
    const payload = { ...values };
    payload.mobile_number = mobileNumber?.mobile_number;
    payload.country_code = mobileNumber?.country_code;

    request({
      url: apiPath.baseURL + "app/invite",
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        console.log("data", data);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          setLoading(false);
          form.resetFields();
        } else {
          setLoading(false);
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (err) => {
        setLoading(false);

        console.log(err);
        ShowToast(err, Severty.ERROR);
      },
    });
  };

  const handleMobileNumberChange = (value, data, type) => {
    let country_code = data?.dialCode;
    setMobileNumber({
      country_code: country_code,
      mobile_number: value.slice(data?.dialCode?.length),
    });
  };

  return (
    <>
      <section className="main">
    
        <div className="account-outers">
          <Row>
            {!isMobile ? <AccountSideNav /> : <BackArrow />}
            <Col span={24} lg={18} xl={15} className="mx-auto  amt-auto">
              <div className="modal-dialog">
              {loading ? (
                      <Loader />
                    ) : (
                      <>
                <div className="modal-content for-invt-frnd ">
             
                  <div className="modal-body invite-frnds-maain">
                
                        {" "}
                        <div className="invite-frnd-img">
                          <img src={InvitefrndImg} alt="" />
                        </div>
                        <div className="invite-txxt">
                          <h3>{lang("Invite your Friends")}</h3>
                          <p>
                          {lang("Invite 10 New friends to join our app and get 20 Loyalty Points to use in amazing rewards!")}
                          </p>
                        </div>
                        <div className="frnds-mail">
                          <Form
                            id="create"
                            form={form}
                            onFinish={onSubmit}
                            layout="vertical"
                          >
                            <Col span={24} xl={24} md={24}>
                              <Form.Item
                                className="username"
                                name="email"
                                rules={[
                                  {
                                    type: "email",
                                    message: lang(
                                      "Please enter a valid email address!"
                                    ),
                                  },
                                  {
                                    max: 255,
                                    message: lang(
                                      "Email address not more then 255 characters!"
                                    ),
                                  },
                                  {
                                    required: true,
                                    message: lang("Please enter email!"),
                                  },
                                ]}
                              >
                                <Input placeholder={lang("Email address")} />
                              </Form.Item>
                            </Col>
                            <Col span={24} md={24}>
                              <Form.Item className="username">
                                <PhoneNumberField
                                  rules={true}
                                  label={""}
                                  name="mobile"
                                  placeholder={lang("Enter Phone Number")}
                                  cover={{ md: 24 }}
                                  colProps={{ sm: 24, span: 24 }}
                                  onChange={handleMobileNumberChange}
                                  number={mobileNumber?.mobile_number}
                                />
                              </Form.Item>
                            </Col>
                            <Col xl={24} md={24} >
                            <div className="reffer-code">
                          <h4>
                            {userProfile?.refer_code
                              ? userProfile.refer_code
                              : ""}
                          </h4>
                          <p>{lang("Your Invite Code")}</p>
                        </div>
                            </Col>
                            <Col xl={24} md={24} className="submit-button-maain">
                              <Button type="submit" htmlType="submit">
                                {lang("Send Invite")}
                              </Button>
                            </Col>
                          
                          </Form>
                        </div>
                      
                     
                  </div>
                  
                </div>
                </>
              )}
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <Footer />
    </>
  );
}

export const BackArrow = ({}) => {
  const navigate = useNavigate();
  return (
    <div className="fillter-sidebar">
      <Link onClick={() => navigate(-1)} className="sidebar-btn">
        <ArrowLeftOutlined />
      </Link>
    </div>
  );
};

export default Invite;
