import React, { useEffect, useState } from "react";
import { Avatar, Button, Image } from "antd";
import GallerymImg from "../../assets/images/gallery-1.png";
import ArrowNewImg from "../../assets/images/ep_arrow-up.png";
import { useNavigate } from "react-router";
import { Navbar, Nav, Container } from "react-bootstrap";
import LogoImg from "../../assets/images/logo img.png";

import Login from "./Login";
import SignUp from "./SignUp";
import lang from "../../helper/langHelper";
import useRequest from "../../hooks/useRequest";
import { useAppContext } from "../../context/AppContext";
import { Severty, ShowToast } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import { useAuthContext } from "../../context/AuthContext";
import Main from "../../components/layout/Main";

function HomeGallary() {
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();
  const { request } = useRequest();

  const { isMobile, language } = useAppContext();
  const [loading, setLoading] = useState(false);

  const [showlogin, setShowLogin] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [data, setData] = useState([]);
  const { userProfile, isLoggedIn } = useAuthContext();

  const toggleGallery = () => {
    setIsExpanded(!isExpanded);
  };

  const fetchData = () => {
    setLoading(true);
    request({
      url: apiPath.gallary,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log("data", data);
        // if (!data.status) return ShowToast(data?.message, Severty.ERROR);
        if (status) setData(data?.docs);
        setLoading(false);
      },
      onError: (err) => {
        setLoading(false);
        console.log(err);
        ShowToast(err, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Main>
        <div className="header extra">
          <Navbar
            bg=""
            expand="lg"
            className="navbar navbar-expand-lg navbar-light"
          >
            <Container>
              <Navbar.Brand onClick={() => navigate("/")}>   <img src={LogoImg} alt="Logo" className="home-logo" style={{cursor : "pointer"}} /></Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarSupportedContent" />
              <Navbar.Collapse id="navbarSupportedContent">
                <Nav className="ms-auto mb-2 mb-lg-0">               
                  <Nav.Link onClick={() => navigate("/login")}        className="nav-link">{lang("Home")}</Nav.Link> 
                  <Nav.Link onClick={() => navigate("/aboutUs")}      className="nav-link">{lang("About us")} </Nav.Link>
                  <Nav.Link onClick={() => navigate("/home-gallary")} className="nav-link active" >{lang("Gallery")}</Nav.Link>
                  {!isLoggedIn ? (
                    <Nav.Item className="header-button-main1">
                      <Button variant="primary"     onClick={() => setShowLogin(true)}    className="btn-first" >  {lang("Login")} </Button>
                      <Button variant="secondary"   onClick={() => setShowSignUp(true)}   className="btn-first-1" >  {lang("Sign Up")}</Button>
                    </Nav.Item>
                  ) : (
                    ""
                  )}
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <section className="gallery-section-4">
          <div className="container">
            <div className="gallery-text-1">
              <h3>{lang("Gallery")}</h3>
              <p>
                {lang("Our gallery highlights the magic of perfectly executed events. Browse through the memories we've crafted for our clients, and imagine your own event coming to life."  )}
              </p>
            </div>
            <div className="gallery-main-img" style={{ cursor: "pointer" }}>
              {data?.length &&
                data?.map((item, index) => (
                  <div
                    className="gallery-img3 gallery-img3-main-gallery-new987"
                    key={index}
                    onClick={() => navigate(`/provider/${item?.provider_id}`)}
                  >
                    <img
                      src={item?.cover_image ? item.cover_image : item?.service_id?.image ? item?.service_id?.image : GallerymImg}
                      alt="#"
                    />
                  </div>
                ))}
            </div>
          </div>
        </section>
        <Login show={showlogin} hide={() => setShowLogin(false)} />
        <SignUp show={showSignUp} hide={() => setShowSignUp(false)} login={() => { setShowLogin(true);  setShowSignUp(false);}}
        />
      </Main>
    </>
  );
}
export default HomeGallary;
