import React, { useState, useEffect, useRef } from "react";
import {
  Checkbox,
  Col,
  Form,
  Select,
  Radio,
  Input,
  Tabs,
  Button,
  Image,
  DatePicker,
  Upload,
  Row,
  InputNumber,
  message,
} from "antd";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Main from "../../components/layout/Main";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate, useParams } from "react-router-dom";

import Serviceback1Img from "../../assets/images/service-img-back.png";
import apiPath from "../../constants/apiPath";
import useRequest from "../../hooks/useRequest";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import SingleImageUpload from "../../components/SingleImageUpload";
import notfound from "../../assets/images/not_found.png";
import Loader from "../../components/Loader";
import { UploadOutlined } from "@ant-design/icons";
import { SketchPicker } from "react-color";
import { ColorCircleComponent, handleSubmitServiceAttr } from "../../helper/functions";

function RequestQuoteIndex() {
  const navigate = useNavigate();
  const params = useParams();
  const { request } = useRequest();
  const [form] = Form.useForm();
  const [proServices, setProServices] = useState([]);
  const [proPkg, setProPkg] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedPkg, setSelectedPkg] = useState(null);
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const [showInfoCol, setShowInfoCol] = useState({});
  const [price, setPrice] = useState(0);
  const urlParams = new URLSearchParams(window.location.search);
  const type = urlParams.get("useFor");
  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];
const formdata = Form.useWatch([], form)
// console.log(formdata,"formdata")
  const handleImage = (data) => {
    data.length > 0 ? setImage(data[0].url) : setImage("");
  };

  const getProviderSerivces = (id) => {
    setLoading(true);
    request({
      url: `${apiPath.providerServices}/${id}`,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        if (!data.status) return;
        setProServices(data.data);
      },
      onError: (err) => {
        setLoading(false);
        console.log(err);
      },
    });
  };

  const getProviderPackage = (id) => {
    setLoading(true);
    request({
      url: `${apiPath.providerPackages}/${id}`,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        if (!data.status) return;
        setProPkg(data.data);
      },
      onError: (err) => {
        setLoading(false);
        console.log(err);
      },
    });
  };

  const onCreate = (values) => {
    console.log("onCreate, called");
    const { package_id, service_id, description, document, ...attributes } =  values;
    let serviceAttr = handleSubmitServiceAttr(attributes,showInfoCol,selectedService?.attribute_id) ?? []


    const payload = {
      service_id: service_id ? service_id : null,
      package_id: package_id ? package_id : null,
      description: description,
      image: image,
      price: price,
      vendor_id: params.id ? params.id : null,
      category_id: proServices?.category_id ? proServices?.category_id : null,
      values: type !== "package" ? serviceAttr : [],
    };

    if (type === "package") {
      payload.pkg_services = selectedPkg?.package_service_id?.map(
        (service) => ({
          pkg_service_id: service?.service_id?._id,
          price: service?.discount_price || 0,
          options: service?.attribute_id?.map((attribute) => {
            const value = attributes[attribute?._id?.name];
            return {
              _id: attribute?._id?._id,
              name: attribute?._id?.name,
              value: value || null,
              type: attribute?._id?.type,
            };
          }),
        })
      );
    }

    //  return console.log(payload,"payload");
    request({
      url:
        type !== "package"
          ? `${apiPath.requestQuote}`
          : `${apiPath.requestQuote}/add-quote-package`,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        console.log(data, "onCreate");
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          setTimeout(() => navigate("/quotation"), 200);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (err) => {
        setLoading(false);
        ShowToast(err, Severty.ERROR);
        console.log(err);
      },
    });
  };

  const handleServiceChange = (serviceId) => {
    const selected = proServices.find( (service) => service?._id === serviceId );
    setSelectedService(selected);
    setPrice(selected?.discount_price ? selected?.discount_price : selected?.original_price );
    console.log("handleServiceChangeselected", selected);
  };

  const handlePkgChange = (pkgId) => {
    const selected = proPkg?.find((pkg) => pkg?._id === pkgId);
    setSelectedPkg(selected);
    setPrice(selected?.discount_price ? selected?.discount_price : selected?.original_price);
  };

  useEffect(() => {
    if (!params.id) return;
    setLoading(true);
    if (type === "package") {
      getProviderPackage(params.id);
    } else if (type === "service") {
      getProviderSerivces(params.id);
    }
  }, [params.id]);

  const handleInfoCol = (attributeName, value) => {
    console.log(attributeName,"attributeName",value, "value" )
    setShowInfoCol((prev) => ({
      ...prev,
      [attributeName]: {
        show: value,
        info: value ? prev[attributeName]?.info || "" : null,
      },
    }));
  };

  return (
    <Main>
      <>
        <section className="request-img-bg">
          <div className="requ-teext-mm">
            <h2>Request a Quote </h2>
          </div>
          <div className="ser-img-back terw">
            <img src={Serviceback1Img} alt="" />
          </div>
        </section>
        <section className="request-write-page">
          <div className="container">
            <div className="request-q-form">
              <div className="rqtxt-1">
                <h2>{lang("Request a Custom Quote")}</h2>
                <p>
                  Let us know your requirements, and we’ll tailor a solution
                  just for you. Our team is dedicated to providing personalized
                  services to meet your needs. Fill out the form below, and
                  we’ll get back to you with a detailed quote.
                </p>
              </div>
              {loading ? (
                <Loader />
              ) : (
                <Form
                  id="create"
                  form={form}
                  onFinish={onCreate}
                  layout="vertical"
                >
                  {type === "package" ? (
                    <Col span={24} md={24}>
                      <Form.Item
                        className="filtter-select  main-new-color-attributes"
                        label="Package"
                        name="package_id"
                        rules={[
                          {
                            required: true,
                            message: lang("Please select the Package!"),
                          },
                        ]}
                      >
                        <Select
                          className="form-select"
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder={lang("Select Package")}
                          showSearch
                          onChange={handlePkgChange}
                        >
                          {proPkg?.map((item) => (
                            <Select.Option
                              key={item?._id}
                              label={item?.name}
                              value={item?._id}
                            >
                              {item?.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  ) : (
                    <Col span={24} md={24}>
                      <Form.Item
                        className="filtter-select  main-new-color-attributes"
                        label="Service"
                        name="service_id"
                        rules={[
                          {
                            required: true,
                            message: lang("Please select the Service!"),
                          },
                        ]}
                      >
                        <Select
                          className="form-select form-select-newmain99"
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder={lang("Select Service")}
                          showSearch
                          onChange={handleServiceChange}
                        >
                          {proServices?.map((item) => (
                            <Select.Option
                              key={item?._id}
                              label={item?.name}
                              value={item?._id}
                            >
                              {item?.name ? item?.name : item?.service_id?.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                    
                  

                


                  {type === "package"
                    ? 
                    selectedPkg?.package_service_id?.map((service) => (
                        <div key={service?._id}>
                          <div className="new-main-class-ofServicemain">
                            <h5>{lang("Service")} : </h5>
                            <h6>{service?.service_id?.name}</h6>
                          </div>
                          <div>
                            <h5 className="new-attribute-tag">{lang("Attributes")}</h5>
                          </div>
                          <Row gutter={24}>
                            {service?.attribute_id?.map((attribute) => (
                                <Col span={24} md={12} key={attribute?._id?._id}>
                                    {attribute?._id?.type === "radio" ? (
                                        <Form.Item
                                          name={ attribute?._id?.name}
                                          label={attribute?._id?.name}
                                          rules={[
                                            {
                                              required: attribute?._id?.is_required,
                                              message: lang(`Please select ${attribute?._id?.name}!`),
                                            },
                                          ]}
                                        >
                                          <Radio.Group>
                                            {attribute?._id?.options?.map((option) => (
                                              <Radio key={option?.name} value={option?.name}>
                                                {option?.name}
                                              </Radio>
                                            ))}
                                          </Radio.Group>
                                        </Form.Item>
                                      ) :
                                      attribute?._id?.type === "checkbox" ? (
                                        <Form.Item
                                          name={ attribute?._id?.name}
                                          label={attribute?._id?.name}
                                          rules={[
                                            {
                                              required: attribute?._id?.is_required,
                                              message: lang(`Please select ${attribute?._id?.name}!`),
                                            },
                                          ]}
                                        >
                                          <Checkbox.Group>
                                              {attribute?._id?.options.map((option) => (
                                                <Checkbox
                                                  value={option?.name}
                                                  key={option?.name}
                                                  label={option?.name}
                                                >
                                                  {option?.name}
                                                </Checkbox>
                                              ))}
                                            </Checkbox.Group>
                                        </Form.Item>
                                      ) :
                                      attribute?._id?.type === "color" ? (
                                        <Form.Item
                                          name={ attribute?._id?.name}
                                          label={attribute?._id?.name}
                                          rules={[
                                            {
                                              required: attribute?._id?.is_required,
                                              message: lang(`Please select ${attribute?._id?.name}!`),
                                            },
                                          ]}
                                        >
                                          <Radio.Group>
                                            {attribute?._id?.options?.map((option) => (
                                              <Radio
                                                value={option?.name}
                                                key={option?.name}
                                              >
                                                {option?.name}
                                                <ColorCircleComponent
                                                  color={option?.name ? option?.name : ""}
                                                />
                                              </Radio>
                                            ))}
                                          </Radio.Group>
                                        </Form.Item>
                                      ) :
                                      attribute?._id?.type === "dropdown" ? (
                                        <Form.Item
                                          name={attribute?._id?.name}
                                          label={attribute?._id?.name}
                                          rules={[
                                            {
                                              required: attribute?._id?.is_required,
                                              message: lang(`Please select ${attribute?._id?.name}!`),
                                            },
                                          ]}
                                        >
                                        <Select
                                              className="form-select"
                                              placeholder={`Select ${attribute?._id?.name}`}
                                            >
                                              {attribute?._id?.options?.map((option) => (
                                                <Select.Option
                                                  value={option?.name}
                                                  key={option?.name}
                                                >
                                                  {option?.name}
                                                </Select.Option>
                                              ))}
                                            </Select>
                                        </Form.Item>
                                      ) :
                                      attribute?._id?.type === "file" ? (
                                        <Form.Item
                                          name={attribute?._id?.name}
                                          label={attribute?._id?.name}
                                          rules={[
                                            {
                                              required: attribute?._id?.is_required,
                                              message: lang(`Please select ${attribute?._id?.name}!`),
                                            },
                                          ]}
                                        >
                                      <SingleImageUpload
                                            fileType={FileType}
                                            imageType={"image"}
                                            btnName={"Image"}
                                            isDimension={false}
                                          />
                                        </Form.Item>
                                      ) :
                                      attribute?._id?.type === "button" ? (
                                        <Form.Item
                                            className="input-mb-o"
                                            name={attribute?._id?.name}
                                            // label={attribute?._id?.name}
                                          >
                                            <Checkbox
                                              valuePropName="checked"
                                              checked={!!showInfoCol[attribute?._id?.name]?.show}
                                              onChange={(e) =>
                                                handleInfoCol(attribute?._id?.name, e.target.checked)
                                              }
                                            >
                                              {attribute?._id?.name}
                                            </Checkbox>

                                            {showInfoCol[attribute?._id?.name]?.show && (
                                              <Col span={12} md={24}>
                                                <Form.Item
                                                  className="input-mb-o"
                                                >
                                                  <Input
                                                    placeholder="Enter Info"
                                                    value={showInfoCol[attribute?._id?.name]?.info || ""}
                                                    onChange={(e) =>
                                                      setShowInfoCol((prev) => ({
                                                        ...prev,
                                                        [attribute?._id?.name]: {
                                                          ...prev[attribute?._id?.name],
                                                          info: e.target.value,
                                                        },
                                                      }))
                                                    }
                                                  />
                                                </Form.Item>
                                              </Col>
                                            )}
                                          </Form.Item>
                                      ) :
                                      attribute?._id?.type === "inputNumber" ? (
                                        <Form.Item
                                          name={attribute?._id?.name}
                                          label={attribute?._id?.name}
                                          rules={[
                                            {
                                              required: attribute?._id?.is_required,
                                              message: lang(`Please select ${attribute?._id?.name}!`),
                                            },
                                          ]}
                                        >
                                      <InputNumber
                                        className="number-inputs"
                                            placeholder={`Enter ${attribute?._id?.name}`}
                                          />
                                        </Form.Item>
                                      ) :
                                      attribute?._id?.type === "textbox" ? (
                                        <Form.Item
                                          name={attribute?._id?.name}
                                          label={attribute?._id?.name}
                                          rules={[
                                            {
                                              required: attribute?._id?.is_required,
                                              message: lang(`Please select ${attribute?._id?.name}!`),
                                            },
                                          ]}
                                        >
                                      <Input
                                            placeholder={`Enter ${attribute?._id?.name}`}
                                          />
                                        </Form.Item>
                                      ) :
                                      attribute?._id?.type === "calendar" ? (
                                        <Form.Item
                                          name={attribute?._id?.name}
                                          label={attribute?._id?.name}
                                          rules={[
                                            {
                                              required: attribute?._id?.is_required,
                                              message: lang(`Please select ${attribute?._id?.name}!`),
                                            },
                                          ]}
                                        >
                                        <DatePicker  format={"DD-MM-yyyy"} />
                                        </Form.Item>
                                      ) :
                                      null}
                                </Col>
                            ))}
                          </Row>
                          
                        </div>
                      ))
                    : 
                    <Row gutter={24}>
                      <>
                    {selectedService?.attribute_id?.length ? 
                    <Col  span={24} md={24}>
                    <div ><h5 className="new-attribute-tag">{lang("Attributes")}</h5></div>
                    </Col>
                     : "" }
                    { selectedService?.attribute_id?.map((attribute) => (
                        <Col span={24} md={12} key={attribute?._id?._id}>
                            {attribute?._id?.type === "radio" ? (
                                <Form.Item
                                  name={ attribute?._id?.name}
                                  label={attribute?._id?.name}
                                  rules={[
                                    {
                                      required: attribute?._id?.is_required,
                                      message: lang(`Please select ${attribute?._id?.name}!`),
                                    },
                                  ]}
                                >
                                  <Radio.Group>
                                    {attribute?._id?.options?.map((option) => (
                                      <Radio key={option?.name} value={option?.name}>
                                        {option?.name}
                                      </Radio>
                                    ))}
                                  </Radio.Group>
                                </Form.Item>
                              ) :
                              attribute?._id?.type === "checkbox" ? (
                                <Form.Item
                                  name={ attribute?._id?.name}
                                  label={attribute?._id?.name}
                                  rules={[
                                    {
                                      required: attribute?._id?.is_required,
                                      message: lang(`Please select ${attribute?._id?.name}!`),
                                    },
                                  ]}
                                >
                                  <Checkbox.Group>
                                      {attribute?._id?.options.map((option) => (
                                        <Checkbox
                                          value={option?.name}
                                          key={option?.name}
                                          label={option?.name}
                                        >
                                          {option?.name}
                                        </Checkbox>
                                      ))}
                                    </Checkbox.Group>
                                </Form.Item>
                              ) :
                              attribute?._id?.type === "color" ? (
                                <Form.Item
                                  name={ attribute?._id?.name}
                                  label={attribute?._id?.name}
                                  rules={[
                                    {
                                      required: attribute?._id?.is_required,
                                      message: lang(`Please select ${attribute?._id?.name}!`),
                                    },
                                  ]}
                                >
                                  <Radio.Group>
                                    {attribute?._id?.options?.map((option) => (
                                      <Radio
                                        value={option?.name}
                                        key={option?.name}
                                      >
                                        {option?.name}
                                        <ColorCircleComponent
                                          color={option?.name ? option?.name : ""}
                                        />
                                      </Radio>
                                    ))}
                                  </Radio.Group>
                                </Form.Item>
                              ) :
                              attribute?._id?.type === "dropdown" ? (
                                <Form.Item
                                  name={attribute?._id?.name}
                                  label={attribute?._id?.name}
                                  rules={[
                                    {
                                      required: attribute?._id?.is_required,
                                      message: lang(`Please select ${attribute?._id?.name}!`),
                                    },
                                  ]}
                                >
                                <Select
                                      className="form-select"
                                      placeholder={`Select ${attribute?._id?.name}`}
                                    >
                                      {attribute?._id?.options?.map((option) => (
                                        <Select.Option
                                          value={option?.name}
                                          key={option?.name}
                                        >
                                          {option?.name}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                </Form.Item>
                              ) :
                              attribute?._id?.type === "file" ? (
                                <Form.Item
                                  name={attribute?._id?.name}
                                  label={attribute?._id?.name}
                                  rules={[
                                    {
                                      required: attribute?._id?.is_required,
                                      message: lang(`Please select ${attribute?._id?.name}!`),
                                    },
                                  ]}
                                >
                              <SingleImageUpload
                                    fileType={FileType}
                                    imageType={"image"}
                                    btnName={"Image"}
                                    isDimension={false}
                                  />
                                </Form.Item>
                              ) :
                              attribute?._id?.type === "button" ? (
                                <Form.Item
                                className="input-mb-o"
                                name={attribute?._id?.name}
                              >
                                <Checkbox
                                  valuePropName="checked"
                                  checked={!!showInfoCol[attribute?._id?.name]?.show}
                                  onChange={(e) =>
                                    handleInfoCol(attribute?._id?.name, e.target.checked)
                                  }
                                  value={showInfoCol[attribute?._id?.name]?.show}
                                >
                                  {attribute?._id?.name}
                                </Checkbox>
                              
                                {showInfoCol[attribute?._id?.name]?.show && (
                                  <Col span={12} md={24}>
                                    <Form.Item
                                      className="input-mb-o"
                                    >
                                      <Input
                                        placeholder="Enter Info"
                                        value={showInfoCol[attribute?._id?.name]?.info || ""}
                                        onChange={(e) =>
                                          setShowInfoCol((prev) => ({
                                            ...prev,
                                            [attribute?._id?.name]: {
                                              ...prev[attribute?._id?.name],
                                              info: e.target.value,
                                            },
                                          }))
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                )}
                              </Form.Item>
                              
                              ) :
                              attribute?._id?.type === "inputNumber" ? (
                                <Form.Item
                                  name={attribute?._id?.name}
                                  label={attribute?._id?.name}
                                  rules={[
                                    {
                                      required: attribute?._id?.is_required,
                                      message: lang(`Please select ${attribute?._id?.name}!`),
                                    },
                                  ]}
                                >
                              <InputNumber
                              className="number-inputs"
                                    placeholder={`Enter ${attribute?._id?.name}`}
                                  />
                                </Form.Item>
                              ) :
                              attribute?._id?.type === "textbox" ? (
                                <Form.Item
                                  name={attribute?._id?.name}
                                  label={attribute?._id?.name}
                                  rules={[
                                    {
                                      required: attribute?._id?.is_required,
                                      message: lang(`Please select ${attribute?._id?.name}!`),
                                    },
                                  ]}
                                >
                              <Input
                                    placeholder={`Enter ${attribute?._id?.name}`}
                                  />
                                </Form.Item>
                              ) :
                              attribute?._id?.type === "calendar" ? (
                                <Form.Item
                                  name={attribute?._id?.name}
                                  label={attribute?._id?.name}
                                  rules={[
                                    {
                                      required: attribute?._id?.is_required,
                                      message: lang(`Please select ${attribute?._id?.name}!`),
                                    },
                                  ]}
                                >
                                <DatePicker format={"DD-MM-yyyy"} />
                                </Form.Item>
                              ) :
                              attribute?._id?.type === "package" ? (
                                <div>                      
                                  {attribute?._id?.options?.map((option) => (
                                    <Form.Item                        
                                      key={option?._id}
                                      label={`${attribute?._id?.name} (${option?.name})`}
                                      name={option?.name}                          
                                    >
                                      <Select
                                        mode="tags"
                                        style={{ width: '100%' }}
                                        placeholder={`Select ${option?.name}`}
                                      
                                      >
                                        {option?.tags?.map((tag) => (
                                          <Select.Option key={tag} value={tag}>
                                            {tag}
                                          </Select.Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                  ))}
                                </div>
                              ) :
                            null} 
                        </Col>
                      ))}
                      
                      </>
                      </Row>
                      
                      }

                  <Col span={24} md={24}>
                    <Form.Item
                      className=""
                      label="Comment"
                      name="description"
                    >
                      <Input.TextArea placeholder="Enter your comments..." />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <div className="col-md-6">
                      <Form.Item
                        className="upload_wrap upload_wrapmain-newww"
                        name="document"
                        label={"Upload  Image"}
                      >
                        <SingleImageUpload
                          value={image}
                          fileType={FileType}
                          imageType={"image"}
                          btnName={"Image"}
                          onChange={(data) => handleImage(data)}
                          isDimension={false}
                        />
                      </Form.Item>

                      <div className="mt-2 add-img-product">
                        {" "}
                        <Image width={120} src={image ?? notfound}></Image>{" "}
                      </div>
                    </div>
                  </Col>
                  <Col span={24} className="mt-3 Send-Requestmain-biutton">
                    <Button
                      form="create"
                      type="primary"
                      className="submit-button"
                      htmlType="submit"
                    >
                      Send Request
                    </Button>
                    {/* <Button form="create" type="primary" htmlType="submit">Send Request</Button> */}
                  </Col>
                </Form>
              )}
            </div>
          </div>
        </section>
      </>
    </Main>
  );
}

const ColorPickerComponent = ({ value = "#1677ff", onChange }) => {
  const [color, setColor] = useState(value);

  const handleChange = (newColor) => {
    setColor(newColor.hex);
    onChange(newColor.hex);
  };

  return <SketchPicker color={color} onChange={handleChange} />;
};

export default RequestQuoteIndex;
