import React, { useState, useEffect, useRef } from "react";

import {
  Row,
  Col,
  Breadcrumb,
  Badge,
  Dropdown,
  Button,
  List,
  Tooltip,
  Input,
  Drawer,
  Typography,
  Menu,
  Image,
  Select,
} from "antd";

import { DownOutlined, MessageOutlined } from "@ant-design/icons";
import countryList from "react-select-country-list";

import {
  Navbar,
  Nav,
  Container,
  NavDropdown,
  Form,
  FormControl,
} from "react-bootstrap";
import { NavLink, Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import LogoImg from "../../assets/images/logo img.png";
import { useAuthContext } from "../../context/AuthContext";
import lang from "../../helper/langHelper";
import notfound from "../../assets/images/not_found.png";
import QuotationIcon from "../../assets/images/quote-new-icon.png";
import SearchiconImg from "../../assets/images/ion_search-outline2.png";
import Prouser from "../../assets/images/user.png";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import Notification from "../../assets/images/notification-white.svg";
import InputGroup from "react-bootstrap/InputGroup";
import searchIcon from "../../assets/images/icon/searchIcon.svg";
import  homeIcon from "../../assets/images/home.png";
import LangIcon from "../../assets/images/language.png"
import MYeventIcon from "../../assets/images/event.png";
import mychatImg from "../../assets/images/bubble-chat.png";
import { useAppContext } from "../../context/AppContext";
import OwlCarousel from "react-owl-carousel3";
import Login from "../../pages/Auth/Login";
import SignUp from "../../pages/Auth/SignUp";

const langOption = [
  {
    label: "English",
    value: "en",
  },
  {
    label: "Arabic",
    value: "ar",
  },
];

function Header({ query, setQuery }) {
  const navigate = useNavigate();
  const path = window.location.pathname;
  console.log("Path:", path);
  const { userProfile, isAdmin, isLoggedIn } = useAuthContext();
  const {
    language,
    setLanguage,
    notification,
    notificationCount,
    setRefreshNotification,
  } = useAppContext();
  const { request } = useRequest();
  useEffect(() => window.scrollTo(0, 0));
  const [countryOption, setCountryOption] = useState([]);
  const [value, setValue] = useState("");
  const [searchItems, setSearchItems] = useState([]);

  const [fox, setFox] = useState(false);
  const searchClose = () => setSearch(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLangOpen, setIsLangOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [login, setLogin] = useState(false);
  const [signup, setSignup] = useState(false);
  const [profileVisible, setProfileVisible] = useState(false);

  const [appSettingVisible, setAppSettingVisible] = useState(false);

  const [search, setSearch] = useState("");
  const [categories, setCategories] = useState([]);
  const [services, setServices] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const searchRef = useRef(null);


  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    handleSearch(e);
    // Perform search logic here
  };

  const responsiveSetting = {
    0: { items: 1 },
    600: { items: 2 },
    1000: { items: 2 },
  };

  useEffect(() => {
    const options = countryList()
      .getData()
      .map((country) => ({
        ...country,
        label: (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span
              className={`fi fi-${country.value.toLowerCase()}`}
              style={{ marginRight: 10 }}
            ></span>
            {lang(country.label)}
          </div>
        ),
      }));
    setCountryOption(options);
    console.log(language, "language...");
  }, [userProfile]);

  const handleSearch = (e) => {
    let qr = e?.target?.value;
    request({
      url: `${apiPath.globalSearch}${e ? "?query=" + qr : ""}`,
      method: "GET",
      onSuccess: (data) => {
        if (data.status) {
          setCategories(() =>
            data?.data?.filter((item) => item.page_type === "category")
          );
          setServices(() =>
            data?.data?.filter((item) => item.page_type === "service")
          );
          setSearchItems(data.data?.slice(0, 10));
        } else {
          console.log(data, "query Data");
        }
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const providerSearchCount = (id, service_id) => {
    const payload = {}
     payload.service_id = service_id ? service_id : null
    request({
      url: apiPath.common.updateSearchCount + "/" + id,
      method: "POST",
      data : payload,
      onSuccess: (data) => {
        console.log(data, "providerSearchCount");
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const handleProfileButton = () => {
    if (!isLoggedIn) return navigate("/login");
    navigate("/account");
  };

  const navigateQuotation = () => {
    if (!isLoggedIn) return navigate("/login");
    navigate("/quotation");
  };
  const navigateChat = () => {
    if (!isLoggedIn) return navigate("/login");
    navigate("/chat");
  };

  const navigateNotification = () => {
    console.log("isLoggedIn+++++++++++", isLoggedIn);
    if (!isLoggedIn) return navigate("/login");
    navigate("/notifications");
  };

  const navigateEventList = () => {
    if (!isLoggedIn) return navigate("/login");
    navigate("/event-list");
  };



  // const getNotification = () => {
  //   request({
  //     url: `${apiPath.notification}/top-five`,
  //     method: "GET",
  //     onSuccess: (data) => {
  //       setNotificationCount(data?.total);
  //     },
  //     onError: (err) => {
  //       console.log(err);
  //     },
  //   });
  // };



  const languages = [
    {
      label: "English (EN)",
      value: "en",
    },
    {
      label: "عربي (ض)",
      value: "ar",
    },
  ];

  



  const langItems = [
    {
      label: ("English (EN)"),
      key: "1",
      // icon: <UserOutlined />,
      danger: true,
    },
    {
      label: ("عربي (ض)"),
      key: "2",
      // icon: <KeyOutlined />,
      danger: true,
    },

  ];

  const handleLangMenuClick = (e) => {
    setIsLangOpen(false);
    if (e.key == 1) {
      localStorage.setItem("languageSet", "en");
      window.location.reload();
    }
    if (e.key == 2) {
      localStorage.setItem("languageSet", "ar");
      window.location.reload();
    }
 
  };

  // const langMenuProps = {
  //   langItems,
  //   onClick: handleLangMenuClick,
  // };

  const langMenuProps = (
    <Menu onClick={handleLangMenuClick}>
      <Menu.Item key="1">English (EN)</Menu.Item>
      <Menu.Item key="2">(ض) عربي </Menu.Item>
    </Menu>
  );

  const items = [
    {
      key: "1",
      label: (
        <Link
          rel="noopener noreferrer"
          className="text-decoration-none"
          to={"/account"}
        >
          {lang("My Account")}
        </Link>
      ),
    },

    {
      key: "2",
      label: (
        <Link
          rel="noopener noreferrer"
          className="text-decoration-none"
          to={"/quotation"}
          onClick={() => navigateQuotation()}
        >
          {" "}
          {lang("My Quotes")}{" "}
        </Link>
      ),
    },
    {
      key: "3",
      label: (
        <Link
          rel="noopener noreferrer"
          className="text-decoration-none"
          to={"/event-list"}
          onClick={() => navigateEventList()}
        >
          {lang("My Event List")}
        </Link>
      ),
    },
    {
      key: "4",
      label: (
        <Link
          rel="noopener noreferrer"
          className="text-decoration-none"
          to={"/chat"}
          onClick={() => navigateChat()}
        >
          {lang("Chat")}
        </Link>
      ),
    },
    {
      key: "5",
      label: (
        <Link
          rel="noopener noreferrer"
          className="text-decoration-none"
          to={"/notifications"}
          onClick={() => {
            navigateNotification();
          }}
        >
          {lang("Notifications")}
        </Link>
      ),
    },
  ];
  const handleMenuClick = (e) => {
    setIsOpen(false);
    if (e.key == 2) {
      setVisible(true);
    }
    if (e.key == 1) {
      setProfileVisible(true);
    }
    if (e.key == 4) {
      setAppSettingVisible(true);
    }
  };
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  useEffect(() => {
    if (!isOpen) return document.body.classList.remove("edit-dropdown");
    document.body.classList.add("edit-dropdown");

    return () => {
      document.body.classList.remove("edit-dropdown");
    };
  }, [isOpen]);

  // useEffect(() => {
  //   if (!isLangOpen) return document.body.classList.remove("edit-dropdown");
  //   document.body.classList.add("edit-dropdown");

  //   return () => {
  //     document.body.classList.remove("edit-dropdown");
  //   };
  // }, [isLangOpen]);

  const inputRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      searchRef.current &&
      !searchRef.current.contains(event.target) &&
      inputRef.current &&
      !inputRef.current.contains(event.target)
    ) {
      setIsDropdownVisible(false);
      setSearch("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const languageItems = [
    {
      key: '1',
      label: 'Item 1',
    },
    {
      key: '2',
      label: 'Item 2',
    },
    {
      key: '3',
      label: 'Item 3',
    },
  ];

  return (
    <>
      {path === "/collab" ? (
        <div></div>
      ) : (
        path !== "/aboutUs" &&
        path !== "/home-gallary" &&
        path !== "/login" &&
        path !== "/signup" && (
          <section className="header_bg">
            <div className="home-page-header">
              <Navbar bg="light" expand="lg" className="home-nav">
                <Container className="header-container">
                  <Navbar.Brand
                    onClick={() => navigate("/")}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={LogoImg} alt="Logo" />
                  </Navbar.Brand>

                  {/* Mobile Css Header  */}
                  <div className="d-lg-none main-second-mobile-nav">
                    <div className="right-header-menu">
                      {/* <div className="country-wrap country-wrapmain-sam">
                        <Select
                          value={language}
                          options={languages}
                          onChange={(value) => {
                            localStorage.setItem("languageSet", value);
                            // setLanguage(value);
                            window.location.reload();
                          }}
                        />
                      </div> */}
                          <div className="profileDropdownMain">
                          <Dropdown
                            open={isLangOpen}
                            onOpenChange={(open) => setIsLangOpen(open)}
                            className="edit-box"
                            overlay={langMenuProps}
                            trigger={["click"]}
                          >
                            <Button className="ant-btn ant-btn-default ant-dropdown-trigger ant-dropdown-open">
                              <div className=" d-flex align-items-center gap-2">
                              
                                <div className="d-none d-xl-block">
                                  <div className="userName">
                                    {"Lang"}
                                    <DownOutlined />
                                  </div>
                                </div>
                              </div>
                            </Button>
                          </Dropdown>
                        </div>
                      {isLoggedIn ? (
                        <>
                          <div className="notification-header d-lg-block">
                            <Tooltip
                              title="Home"
                              color="#fff"
                              overlayInnerStyle={{
                                color: "#000",
                                padding: "8px 12px",
                                borderRadius: "4px",
                              }}
                            >
                              <Button
                                onClick={() => {
                                  navigate("/");
                                }}
                                className="notifiaction-buttin-main quotation-icons"
                              >
                                <img src={homeIcon} alt="Home" />
                               
                              </Button>
                            </Tooltip>
                          </div>
                         <div className="profileDropdownMain">
                          <Dropdown
                            open={isLangOpen}
                            onOpenChange={(open) => setIsLangOpen(open)}
                            className="edit-box"
                            overlay={langMenuProps}
                            trigger={["click"]}
                          >
                            <Button className="ant-btn ant-btn-default ant-dropdown-trigger ant-dropdown-open quotation-icons">
                              <div className=" d-flex align-items-center gap-2">
                              
                                <div className=" ">
                                  <div className="userName">
                                     <img src={LangIcon} />
                                  </div>
                                </div>
                              </div>
                            </Button>
                          </Dropdown>
                        </div>
                          <div className="profileDropdownMain">
                            <Dropdown
                              open={isOpen}
                              onOpenChange={(open) => setIsOpen(open)}
                              className="edit-box"
                              menu={menuProps}
                              trigger={["click"]}
                            >
                              <Button className="ant-btn ant-btn-default ant-dropdown-trigger ant-dropdown-open">
                                <div className="d-flex align-items-center gap-2">
                                  <div className="userImg">
                                    <Image
                                      src={
                                        userProfile?.image
                                          ? userProfile.image
                                          : Prouser
                                      }
                                      preview={false}
                                    />
                                  </div>
                                  <div className="d-none d-xl-block">
                                    <div className="userName">
                                      {`${
                                        userProfile?.name
                                          ? userProfile.name
                                          : "Guest"
                                      }`}
                                      {/* <DownOutlined /> */}
                                    </div>
                                  </div>
                                </div>
                              </Button>
                            </Dropdown>
                          </div>
                        </>
                      ) : (
                        <>
                        {/* <Button
                          className="select-inn main-budgut-444"
                          // onClick={() => navigate("/login")}
                          onClick={() => setLogin(true)}
                        >
                          Login
                        </Button>
                        <Button
                          className="select-inn main-budgut-444"
                          // onClick={() => navigate("/login")}
                          onClick={() => setSignup(true)}
                        >
                          SignUp
                        </Button> */}
                      </>
                      )}
                    </div>
                  </div>

                  {/* Web Css Header */}
                  <Navbar.Collapse id="navbarSupportedContent">
                    {isLoggedIn ? (
                      <Nav className="mx-auto mb-2 mb-lg-0">                     
                        <div
                          className="search-main headerSearch"
                          ref={inputRef}
                        >
                          <div className="search-container">
                            <Input
                              type="search"
                              className="search-input rounded-1 pe-5"
                              id="headerSearch"
                              placeholder="Search by Categories , Services name..."
                              onChange={handleSearchChange}
                              value={search}
                              onFocus={() => setIsDropdownVisible(true)}
                              bordered={false}
                            />
                            <label
                              htmlFor="headerSearch"
                              className="btn searchBtn d-flex align-items-center justify-content-center m-0 rounded-1 border-0 shadow-none position-absolute start-0 top-0 bottom-0 me-1 my-auto"
                            >
                              <img
                                className=""
                                width={20}
                                src={searchIcon}
                                alt="search"
                              />
                            </label>
                          </div>

                          {isDropdownVisible && search && (
                            <div
                              className="search-dropdown search-dropdown-new-mainhh"
                              ref={searchRef}
                            >
                              <div className="row">
                                <div className="col-lg-4 col-12">
                                  <p className="title-search">
                                    {categories?.length
                                      ? "Categories"
                                      : "No Categories Found"}
                                  </p>
                                  <ul className="category-list">
                                    {categories?.map((category, index) => (
                                      <li key={index}>
                                        <Link
                                          to={`/service/${category._id}`}
                                          className="link-search"
                                        >
                                          {category.name}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                                <div className="col-lg-8 col-12">
                                  <div className="d-flex justify-content-between mb-3 align-items-center">
                                    <p className="title-search">
                                      {services?.length
                                        ? "Services"
                                        : "No Services Found"}
                                    </p>
                                  </div>
                                  <OwlCarousel
                                    responsive={responsiveSetting}
                                    className="owl-searchbar"
                                    loop={false}
                                    margin={10}
                                    dots={false}
                                  >
                                    {services?.map((item, index) => (
                                      <div className="item" key={index}>
                                        <div className="product-item">
                                          <div className="product-img01">
                                            <Link
                                              onClick={() =>
                                                providerSearchCount(
                                                  item?.provider_id?._id,
                                                  item?.service_id?._id,


                                                )
                                              }
                                              to={`/provider/${item?.provider_id?._id}`}
                                            >
                                              <img
                                                src={
                                                  item?.cover_image ?? item?.service_id?.image
                                                }
                                                alt={""}
                                              />
                                            </Link>
                                          </div>
                                          <div className="product-title">
                                            <Link
                                              onClick={() =>
                                                providerSearchCount(
                                                  item?.provider_id?._id,
                                                  item?.service_id?._id,
                                                )
                                              }
                                              to={`/provider/${item?.provider_id?._id}`}
                                            >
                                              {item?.name}
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </OwlCarousel>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </Nav>
                    ) : (
                      ""
                    )}

                    <div className="right-header-menu right-header-menu-main-445">
                      {/* <Select
                        className="select-inn main-budgut-444"
                        placeholder={lang("Select Language")}
                        value={language}
                        options={languages}
                        onChange={(value) => {
                          localStorage.setItem("languageSet", value);
                          console.log(value.value, "value");
                          // setLanguage(value);
                          window.location.reload();
                        }}
                      /> */}
                      <div className="notification-header d-lg-block">
                            <Tooltip
                              title="Home"
                              color="#fff"
                              overlayInnerStyle={{
                                color: "#000",
                                padding: "8px 12px",
                                borderRadius: "4px",
                              }}
                            >
                              <Button
                                onClick={() => {
                                  navigate("/");
                                }}
                                className="notifiaction-buttin-main quotation-icons"
                              >
                                <img src={homeIcon} alt="Home" />
                               
                              </Button>
                            </Tooltip>
                          </div>
                         <div className="profileDropdownMain">
                          <Dropdown
                            open={isLangOpen}
                            onOpenChange={(open) => setIsLangOpen(open)}
                            className="edit-box"
                            overlay={langMenuProps}
                            trigger={["click"]}
                          >
                            <Button className="ant-btn ant-btn-default ant-dropdown-trigger ant-dropdown-open quotation-icons">
                              <div className=" d-flex align-items-center gap-2">
                              
                                <div className="d-none d-lg-block">
                                  <div className="userName">
                                     <img src={LangIcon} />
                                  </div>
                                </div>
                              </div>
                            </Button>
                          </Dropdown>
                        </div>

                      {/* </div> */}
                      {isLoggedIn ? (
                        <>
                         
                          <div className="notification-header d-lg-block">
                            <Tooltip
                              title="Notifications"
                              color="#fff"
                              overlayInnerStyle={{
                                color: "#000",
                                padding: "8px 12px",
                                borderRadius: "4px",
                              }}
                            >
                              <Button
                                onClick={() => {
                                  navigateNotification();
                                }}
                                className="notifiaction-buttin-main quotation-icons"
                              >
                                <img src={Notification} alt="Notification" />
                                {notificationCount ? (
                                  <span className="active_notification active_notification-999 ">
                                    {notificationCount ?? 0}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </Button>
                            </Tooltip>
                          </div>
                          <Tooltip
                            title="Chat"
                            color="#fff"
                            overlayInnerStyle={{
                              color: "#000",
                              padding: "8px 12px",
                              borderRadius: "4px",
                            }}
                          >
                            <Button
                              className="quotation-icons quotation-icons-main95h"
                              onClick={() => navigateChat()}
                            >
                              <img src={mychatImg} alt="Chat" />
                              {/* Uncomment and use if needed */}
                              {/* <span className="active_notification active_notification-999 active_notification-998">
          0
        </span> */}
                            </Button>
                          </Tooltip>
                          <Tooltip
                            title="Quotations"
                            color="#fff"
                            overlayInnerStyle={{
                              color: "#000",
                              padding: "8px 12px",
                              borderRadius: "4px",
                            }}
                          >
                            <Button
                              className="quotation-icons"
                              onClick={() => navigateQuotation()}
                            >
                              <img src={QuotationIcon} alt="Quotations" />
                            </Button>
                          </Tooltip>
                          <Tooltip
                            title="Events"
                            color="#fff"
                            overlayInnerStyle={{
                              color: "#000",
                              padding: "8px 12px",
                              borderRadius: "4px",
                            }}
                          >
                            <Nav.Link>
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => navigateEventList()}
                                className="icon-event-hh"
                              >
                                <img src={MYeventIcon} alt="Events" />
                              </span>
                            </Nav.Link>
                          </Tooltip>
                          <div
                            className="profileDropdownMain"
                            onClick={() => handleProfileButton()}
                          >
                            <Button className="ant-btn ant-btn-default ant-dropdown-trigger ant-dropdown-open">
                              <div className="d-flex align-items-center gap-2">
                                <div className="userImg">
                                  <Image
                                    src={
                                      userProfile?.image
                                        ? userProfile.image
                                        : Prouser
                                    }
                                    preview={false}
                                  />
                                </div>
                                <div className="d-none d-xl-block">
                                  <div className="userName">
                                    {language !== "en" && language !== null
                                      ? userProfile?.[`${language}_name`] ??
                                        userProfile?.name
                                      : userProfile?.name}
                                  </div>
                                </div>
                              </div>
                            </Button>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </Navbar.Collapse>

                  {isLoggedIn ? (
                    <div className="headerSearch d-lg-none headerSearch-responsive">
                      <div className="search-main headerSearch">
                        <div className="search-container">
                          <Input
                            type="search"
                            className="search-input rounded-1 pe-5"
                            id="headerSearch"
                            placeholder={
                              lang("Search by Categories , Services name") +
                              "..."
                            }
                            onChange={handleSearchChange}
                            value={search}
                            bordered={false}
                          />
                          <label
                            htmlFor="headerSearch"
                            className="btn searchBtn d-flex align-items-center justify-content-center m-0 rounded-1 border-0 shadow-none position-absolute start-0 top-0 bottom-0 me-1 my-auto"
                          >
                            <img
                              className=""
                              width={20}
                              src={searchIcon}
                              alt="search"
                            />
                          </label>
                        </div>

                        {search && (
                          <div
                            className="search-dropdown search-dropdown-new-mainhh"
                            ref={searchRef}
                          >
                            <div className="row">
                              <div className="col-lg-4 col-12">
                                <p className="title-search">
                                  {categories?.length
                                    ? "Categories"
                                    : "No Categories Found"}
                                </p>
                                <ul className="category-list">
                                  {categories?.map((category, index) => (
                                    <li key={index}>
                                      <Link
                                        to={`/service/${category._id}`}
                                        className="link-search"
                                      >
                                        {category.name}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                              <div className="col-lg-8 col-12">
                                <div className="d-flex justify-content-between mb-3 align-items-center">
                                  <p className="title-search">
                                    {services?.length
                                      ? "Services"
                                      : "No Services Found"}
                                  </p>
                                </div>
                                <OwlCarousel
                                  responsive={responsiveSetting}
                                  className="owl-searchbar"
                                  loop={false}
                                  margin={10}
                                  dots={false}
                                >
                                  {services?.map((item, index) => (
                                    <div className="item" key={index}>
                                      <div className="product-item">
                                        <div className="product-img01">
                                          <Link
                                            onClick={() =>
                                              providerSearchCount(
                                                item?.provider_id?._id,
                                                item?.service_id?._id,

                                              )
                                            }
                                            to={`/provider/${item?.provider_id?._id}`}
                                          >
                                            <img
                                              src={
                                                item?.src_image ?? item?.image
                                              }
                                              alt={item?.service}
                                            />
                                          </Link>
                                        </div>
                                        <div className="product-title">
                                          <Link
                                            onClick={() =>
                                              providerSearchCount(
                                                item?.provider_id?._id,
                                                item?.service_id?._id,

                                              )
                                            }
                                            to={`/provider/${item?.provider_id?._id}`}
                                          >
                                            {item.service}
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </OwlCarousel>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ) :
                   (
                    <div className="d-flex gap-2">
                      <Button
                        className="select-inn main-budgut-444"
                        // onClick={() => navigate("/login")}
                        onClick={() => setLogin(true)}
                      >
                        Login
                      </Button>
                      <Button
                        className="select-inn main-budgut-444"
                        // onClick={() => navigate("/login")}
                        onClick={() => setSignup(true)}
                      >
                        SignUp
                      </Button>
                    </div>
                  )}
                </Container>
              </Navbar>
            </div>
          </section>
        )
      )}

      <Login show={login} hide={() => setLogin(false)} />
      <SignUp
        show={signup}
        hide={() => setSignup(false)}
        login={() => {
          setLogin(true);
          setSignup(false);
        }}
      />
    </>
  );
}

export default Header;
