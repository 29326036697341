import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Main from "../../components/layout/Main";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, useParams } from "react-router";
import InviteFriends from "../../components/InviteFriends";
import apiPath from "../../constants/apiPath";
import useRequest from "../../hooks/useRequest";
import Loader from "../../components/Loader";
import { Image, Input } from "antd";
import useDebounce from "../../hooks/useDebounce";
import lang from "../../helper/langHelper";
import notfound from "../../assets/images/not-found-img.png";
import AddBudget from "../Provider/AddBudget";
import ConfirmModal from "../../components/ConfirmDeleteModal";
import { Severty, ShowToast } from "../../helper/toast";

function AddBudgetCategory() {
  const navigate = useNavigate();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 300);
  const params = useParams();
  const [showConfirm, setShowConfirm] = useState(false);
  const [selected, setSelected] = useState({
    event_id: undefined,
    category_id: undefined,
  });

  const getCategories = (id) => {
    setLoading(true);
    request({
      url: `${apiPath.common.categories}?search=${debouncedSearchText}`,
      method: "GET",
      onSuccess: ({ data }) => {
        setLoading(false);
        console.log(data, "data......");
        setCategories(data?.data);
      },
      onError: (err) => {
        setLoading(false);
        console.log(err);
      },
    });
  };

  const addBudgetCategory = (id) => {
    setLoading(true);
    const { event_id, category_id } = selected;
    const payload = {
      event_id: event_id,
      category_id: category_id,
    };
    request({
      url: `${apiPath.addBudgetCategory}`,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          navigate(-1);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (err) => {
        setLoading(false);
        console.log(err);
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    getCategories(params.id);
  }, [debouncedSearchText, refresh, params.id]);

  return (
    <Main>
      <>
        <section>
          <div className="container">
            <div className="main-quotation-detailss">
              <div className="pageHeadingSearch">
                <Input.Search
                  className="searchInput"
                  placeholder={lang("Search by category name...")}
                  onChange={(e) => setSearchText(e.target.value)}
                  allowClear
                />
              </div>
              <div>
                <Row gutter={[24, 24]} className="pt-3">
                  {loading ? (
                    <Loader />
                  ) : categories?.length && categories.length > 0 ? (
                    categories?.map((item) => (
                      <Col span={24} md={12} key={"provider._id"} style={{cursor : "pointer"}}>
                        <div className="venue-lalsion">
                          <div
                            className="venue-lalsion-1"
                            onClick={() => {
                              setShowConfirm(true);
                              setSelected({
                                event_id: params.id,
                                category_id: item?._id,
                              });
                            }}
                          >
                            <div className="venue-img">
                              <div className="venue-main-immg">
                                <img
                                  src={item?.image ? item?.image : notfound}
                                  alt=""
                                />
                              </div>
                              <div className="venie-m-txt">
                                <h2>{item?.name ?? ""}</h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))
                  ) : (
                    <div>No Attached Categories </div>
                  )}
                </Row>
              </div>
            </div>
          </div>
        </section>

        <InviteFriends />
        {showConfirm && (
          <ConfirmModal
            show={showConfirm}
            hide={() => {
              setShowConfirm(false);
              setSelected("");
            }}
            title={"Add Budget Category"}
            subtitle={`Are you sure you want to add this Category?`}
            refresh={() => setRefresh((prev) => !prev)}
            onOk={() => addBudgetCategory()}
          />
        )}
      </>
    </Main>
  );
}

export default AddBudgetCategory;
