import React, { createContext, useState, useContext, useEffect } from "react";
import { useAuthContext } from "./AuthContext";
import apiPath from "../constants/apiPath";
import axios from "axios";

export const AppStateContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [pageHeading, setPageHeading] = useState("Heading");
  const [language, setLanguage] = useState("en");
  const [loading, setLoading] = useState(true);
  const [notification, setNotification] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [refreshNotification, setRefreshNotification] = useState(false);

  const { userProfile } = useAuthContext();
  const [cities, setCities] = useState([]);

  const [country, setCountry] = useState({
    country_id: undefined,
    currency: undefined,
    data: undefined,
  });

  useEffect(() => {
    setLoading(true);
    let lang = localStorage.getItem("languageSet");
    lang = lang ? typeof lang === "string" ? lang : "en" : "en";

    if (lang == "ar") {
      //   import("../assets/styles/rtl.css");
      import("../assets/styles/main.css");
    } else {
      import("../assets/styles/main.css");
    }

    setTimeout(() => setLoading(false), 200);
    setLanguage(lang);
  }, []);

  const getCities = async ({ id, onSuccess }) => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      };
      const response = await axios.get(
        apiPath.baseURL + `/country-city/${id}`,
        {
          headers,
        },
      );
      const data = response.data.data;
      if (data) {
        let cData = [];
        if (userProfile.type == "Admin") {
          cData = data;
        } else {
          const city_ids = userProfile.city_ids.map(({ _id }) => _id);
          cData = data.filter((item) => city_ids.includes(item._id));
        }
        console.log(cData, "cData");
        setCities(cData);
        if (onSuccess) onSuccess(cData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getNotification = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      };
      const response = await axios.get(apiPath.baseURL + `app/notification/top-five`, {
        headers,
      });
      const data = response.data
      if (data) {   
        setNotification(data.data);
        setNotificationCount(data?.total ?? 0)
        setRefreshNotification(false)    
      }

      } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!country.country_id) return;
    if (!userProfile) return;
    getCities({ id: country.country_id });
  }, [country.country_id, userProfile]);


  useEffect(() => {
    if (!userProfile) return;   
      getNotification()
  }, [userProfile,refreshNotification]);

  return (
    <AppStateContext.Provider
      value={{
        pageHeading,
        setPageHeading,
        setCountry,
        country,
        language,
        setLanguage,
        cities,
        getCities,
        notification,
        notificationCount,
        setRefreshNotification
      }}
    >
      {loading ? null : children}
    </AppStateContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppStateContext);
};
