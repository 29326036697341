import React, { useState } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Main from "../components/layout/Main";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import InviteFriends from "../components/InviteFriends";
import { Container } from "react-bootstrap";
import { Card, Button } from "antd";
import BlogImg from "../assets/images/imagefor-blogpage.png";
import ArrowImg from "../assets/images/right-arrow.png";

const { Meta } = Card;

function BlogdetailsIndex() {
  const navigate = useNavigate();

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <Main>
      <>
        <section>
          <Container>
            <div className="bannerclass">
              <div className="container">
                <div className="row">
                  <div className="blog-page-detail-maain">
                    <div className="main-heading-title">
                      {" "}
                      <h3>Blog detail</h3>
                    </div>
                    <div className="blogimage">
                      <img src={BlogImg} />
                    </div>
                    <div className="main-heading-date">
                      {" "}
                      <h3>11th Jan 2024</h3>
                    </div>
                    <div className="heading_333">
                      <h3>Event</h3>
                      <p>
                        Social media is an influential and powerful medium for
                        content. Whether you are hosting a trade show or a
                        business conference, social media is a necessary tool in
                        your marketing kit. It is a medium that can be used
                        before, during, and after the event.{" "}
                      </p>
                      <p>
                        With catchy writing and attractive graphics, you can use
                        social media posts to advertise your event leading up to
                        it. During the function, you can share live updates,
                        real-time photos, and relevant information to better
                        engage attendees and inspire people who didn’t attend to
                        be sure to buy tickets for your next event. Once the
                        occasion is over, you can share highlights as video
                        content and key takeaways to expand the presence of your
                        brand.
                      </p>
                      <p>
                        Emails can be a vehicle to effectively market your
                        event. You can advertise its unique features and ensure
                        attendees have the right details. You can use emails to
                        confirm ticket purchases. Also, you can share emails
                        after the event to establish a more meaningful
                        relationship with your customers and encourage them to
                        attend other functions you host in future. Email
                        campaigns are also great for sharing monthly newsletters
                        with your clients to keep them updated on your company’s
                        projects.{" "}
                      </p>
                    </div>

                    <div
                      className="button-main1 justify-content-start"
                      id="main-2"
                    >
                      <Button className="button1" type="primary">
                        <img src={ArrowImg} /> Back{" "}
                      </Button>
                      <Button className="button2" type="primary">
                        {" "}
                        Next <img src={ArrowImg} />{" "}
                      </Button>
                    </div>

                    <div className="related-post">
                      <h3>Related Posts</h3>
                      <div className="main-aayur">
                        <Card
                          hoverable
                          style={{
                            width: 420,
                          }}
                          cover={<img alt="example" src={BlogImg} />}
                        >
                          <div className="main-heading-date">
                            {" "}
                            <h3>11th Jan 2024</h3>
                          </div>
                          <div className="blog_pge-txt">
                            <h5>Plan The Best. Day. Ever</h5>
                            <p>
                              Thinking of a casual, relaxing day out? Plan
                              something great with
                            </p>
                            <Button type="primary">
                              Read More <img alt="example" src={ArrowImg} />{" "}
                            </Button>
                          </div>
                        </Card>
                        <Card
                          hoverable
                          style={{
                            width: 420,
                          }}
                          cover={<img alt="example" src={BlogImg} />}
                        >
                          <div className="main-heading-date">
                            {" "}
                            <h3>11th Jan 2024</h3>
                          </div>
                          <div className="blog_pge-txt">
                            <h5>Plan The Best. Day. Ever</h5>
                            <p>
                              Thinking of a casual, relaxing day out? Plan
                              something great with
                            </p>
                            <Button type="primary">
                              Read More <img alt="example" src={ArrowImg} />{" "}
                            </Button>
                          </div>
                        </Card>
                        <Card
                          hoverable
                          style={{
                            width: 420,
                          }}
                          cover={<img alt="example" src={BlogImg} />}
                        >
                          <div className="main-heading-date">
                            {" "}
                            <h3>11th Jan 2024</h3>
                          </div>
                          <div className="blog_pge-txt">
                            <h5>Plan The Best. Day. Ever</h5>
                            <p>
                              Thinking of a casual, relaxing day out? Plan
                              something great with
                            </p>
                            <Button type="primary">
                              Read More <img alt="example" src={ArrowImg} />{" "}
                            </Button>
                          </div>
                        </Card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>

        <InviteFriends />
      </>
    </Main>
  );
}

export default BlogdetailsIndex;
