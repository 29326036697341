import { Select, Form, Row, Col, Drawer, Space, Slider } from "antd";

import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel3";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Main from "../../components/layout/Main";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Collapse } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import FilterbuttonImg from "../../assets/images/filterbutton.png";
import InviteFriends from "../../components/InviteFriends";
import apiPath from "../../constants/apiPath";
import useRequest from "../../hooks/useRequest";

import NoService from "../../assets/images/noservice.png";
import notfound from "../../assets/images/not-found-img.png";
import Loader from "../../components/Loader";
import lang from "../../helper/langHelper";
import { UndoOutlined } from "@ant-design/icons";
import ShortingImg from "../../assets/images/shortimg.png";
import { useAppContext } from "../../context/AppContext";
import CommonFunction from "../../helper/CommonFunction"


function ServiceByEvent() {
  const navigate = useNavigate();
  const {language} = useAppContext()
  const [open, setOpen] = useState(true);
  const { request } = useRequest();
  const params = useParams();
  const [loading, setLoading] = useState([]);
  const [services, setServices] = useState([]);
  const [events, setEvents] = useState([]);
  const commonFunction = CommonFunction()

  const [filter, setFilter] = useState({
    event_id: params.id ? params.id : undefined,
    service_id: undefined,
    sort: undefined,
    key_filter: "all",
  });

  const getServices = (id) => {
    setLoading(true);
    const queryString = Object.entries(filter)
      .filter(([_, v]) => v != undefined)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    console.log(queryString, "query----------st12ring------>");

    request({
      url: `${apiPath.services}?search=${""}${
        queryString ? `&${queryString}` : ""
      }`, 
      method: "POST",
      onSuccess: ({ docs, status }) => {
        setLoading(false);
        console.log(docs, "docs")
        if (status) {
          setServices(docs);
        }
      },
      onError: (err) => {
        setLoading(false);

        console.log(err);
      },
    });
  };

  const getEvents = () => {
    request({
      url: `${apiPath.common.eventType}`,
      method: "GET",
      onSuccess: ({ data }) => {
        setEvents(data?.data);
      },
    });
  };

  useEffect(() => {
    if (!params.id) return;
    setLoading(true);
    getServices(params.id);
    getEvents();
  }, [filter, params]);

  const onChange = (key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
  };

  const handleSetEventParams = (id) => {
    navigate(`/service-by-event/${id}`);
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [placement, setPlacement] = useState("left");

  const showDrawer = () => {
    setIsDrawerOpen(true);
  };

  const onClose = () => {
    setIsDrawerOpen(false);
  };

  const handlePriceRangeChange = (value) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      min_price: value[0],
      max_price: value[1],
    }));
  };

  const handleNavProvider = (id) => {
    let vendor_id = id ? id : "";
    commonFunction.updateProviderViewCount(vendor_id)
  };

  return (
    <Main>
      <>
        <section className="filter-bg">
          <div className="container">
            <Row gutter={24}>
              <Col span={24} md={6} lg={6} className="d-none d-lg-block">
                <div className="panel-group">
                  <div className="panel panel-default main-filter-btn-main-145">
                    <div className="panel-heading main-filter-btn-main-45">
                      <Button
                        // onClick={() => setOpen(!open)}
                        // aria-controls="filter-collapse"
                        // aria-expanded={open}
                        className="float-end filter-button-no-reset"
                      >
                        <div>
                          <img src={FilterbuttonImg} alt="Filter button" />
                          <span>{lang("Filter")}</span>
                        </div>
                      </Button>
                      <Button
                        onClick={() => {
                          setFilter({
                            event_id: params.id ? params.id : undefined,
                            service_id: undefined,
                            sort: undefined,
                            key_filter: "all",
                          });
                        }}
                        type="primary"
                        className="filter-button-reset"
                      >
                        <div>
                          <UndoOutlined />
                          <span>{lang("Reset")}</span>
                        </div>
                      </Button>
                    </div>
                    {/* <Collapse in={open}> */}

                    <div className="baccolor">
                      <div className="first-bg">
                        <div className="manager">
                          <h6>{lang("Event Type")}</h6>

                          <Form.Item className="filtter-select">
                            <Select
                              className="form-select"
                              aria-label="Default select example"
                              placeholder={lang("Select Event")}
                              onChange={(e) => {
                                handleSetEventParams(e);
                                onChange("event_id", e);
                              }}
                              value={filter.event_id}
                            >
                              {events?.length &&
                                events?.map((item) => (
                                  <Select.Option
                                    value={item._id}
                                    key={item._id}
                                  >
                                   { language !== "en" && language !== null
                                ? item?.[`${language}_name`] ?? item?.name
                                : item?.name }
                                  </Select.Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </div>

                         <div className="manager">
                            <h6>{lang("Price")}</h6>
                          
                            <div>
                              {/* Slider component */}
                              <div className="range-input">
                                <Slider
                                  range
                                  min={0}
                                  max={10000}
                                  step={100}
                                  defaultValue={[
                                    filter.min_price,
                                    filter.max_price,
                                  ]}
                                  value={[filter.min_price, filter.max_price]}
                                  onChange={handlePriceRangeChange}
                                />
                              </div>
                              {/* Display current range values */}
                              <div style={{ marginTop: 20 }}>
                                {lang("Selected Range:")} {filter.min_price} -{" "}
                                {filter.max_price}
                              </div>
                            </div>
                          
                          </div>
                          <div className="manager">
                            <h6>
                              <img src={ShortingImg} alt="" /> {lang("Sort By")}
                            </h6>
                            <div className="radio-container-maain">
                              <label className="radio-container">
                                <input
                                  checked={filter.sort === "A_Z"}
                                  type="radio"
                                  name="radio-option"
                                  onChange={(value) => onChange("sort", "A_Z")}
                                />
                                <span className="radio-checkmark" />
                                <img
                                  src="image/bi_sort-alpha-up.png"
                                  alt=""
                                />{" "}
                                {lang("A to Z")}
                              </label>

                              <label className="radio-container">
                                <input
                                  checked={filter.sort === "Z_A"}
                                  type="radio"
                                  name="radio-option"
                                  onChange={(value) => onChange("sort", "Z_A")}
                                />
                                <span className="radio-checkmark" />
                                <img
                                  src="image/bi_sort-alpha-down.png"
                                  alt=""
                                />{" "}
                                {lang("Z to A")}
                              </label>
                              <label className="radio-container">
                                <input
                                  checked={filter.sort === "most_view"}
                                  type="radio"
                                  name="radio-option"
                                  onChange={(value) =>
                                    onChange("sort", "most_view")
                                  }
                                />
                                <span className="radio-checkmark" />
                                <img
                                  src="image/bi_sort-alpha-down.png"
                                  alt=""
                                />{" "}
                                {lang("Most View")}
                              </label>
                              <label className="radio-container">
                                <input
                                  type="radio"
                                  name="radio-option"
                                  checked={filter.sort === "Price_L_to_H"}
                                  onChange={(value) =>
                                    onChange("sort", "Price_L_to_H")
                                  }
                                />
                                <span className="radio-checkmark" />
                                <img src="image/pricedown.png" alt="" /> 
                                {lang("Price Low to High")}
                              </label>
                              <label className="radio-container">
                                <input
                                  type="radio"
                                  name="radio-option"
                                  checked={filter.sort === "Price_H_to_L"}
                                  onChange={(value) =>
                                    onChange("sort", "Price_H_to_L")
                                  }
                                />
                                <span className="radio-checkmark" />
                                <img src="image/pricelow.png" alt="" />
                                 {lang("Price High to Low")}
                              </label>
                            </div>
                          </div>
                      </div>
                    </div>

                    
                  </div>
                </div>
              </Col>

              <Col span={24} md={24} lg={18}>
                <div className="result-and-shot-div">
                  <div className="for-filterbutton-drowerss d-lg-none d-md-block">
                    <Button type="primary" onClick={showDrawer}>
                      <img src={FilterbuttonImg} alt="Filter button" />
                    </Button>

                    <Drawer
                      title={lang("Filter")}
                      placement={placement}
                      width={350}
                      onClose={onClose}
                      open={isDrawerOpen}
                      extra={
                        <Space>
                          <Button
                            onClick={() => {
                              setFilter({
                                category_id: params.id ? params.id : undefined,
                                sub_category_id: undefined,
                                service_id: undefined,
                                sort: undefined,
                                rate: undefined,
                                key_filter: "all",
                              });
                            }}
                            type="primary"
                            className="filter-button-reset filter-button-reset-44"
                          >
                            <div>
                              <UndoOutlined />
                              <span>{lang("Reset")}</span>
                            </div>
                          </Button>
                        </Space>
                      }
                    >
                       <div className="first-bg">
                        <div className="manager">
                          <h6>{lang("Event Type")}</h6>

                          <Form.Item className="filtter-select">
                            <Select
                              className="form-select"
                              aria-label="Default select example"
                              placeholder={lang("Select Event")}
                              onChange={(e) => {
                                handleSetEventParams(e);
                                onChange("event_id", e);
                              }}
                              value={filter.event_id}
                            >
                              {events?.length &&
                                events?.map((item) => (
                                  <Select.Option
                                    value={item._id}
                                    key={item._id}
                                  >
                                   { language !== "en" && language !== null
                                ? item?.[`${language}_name`] ?? item?.name
                                : item?.name }
                                  </Select.Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </div>

                         <div className="manager">
                            <h6>{lang("Price")}</h6>
                          
                            <div>
                              {/* Slider component */}
                              <div className="range-input">
                                <Slider
                                  range
                                  min={0}
                                  max={10000}
                                  step={100}
                                  defaultValue={[
                                    filter.min_price,
                                    filter.max_price,
                                  ]}
                                  value={[filter.min_price, filter.max_price]}
                                  onChange={handlePriceRangeChange}
                                />
                              </div>
                              {/* Display current range values */}
                              <div style={{ marginTop: 20 }}>
                                {lang("Selected Range:")} {filter.min_price} -{" "}
                                {filter.max_price}
                              </div>
                            </div>
                          
                          </div>
                          <div className="manager">
                            <h6>
                              <img src={ShortingImg} alt="" /> {lang("Sort By")}
                            </h6>
                            <div className="radio-container-maain">
                              <label className="radio-container">
                                <input
                                  checked={filter.sort === "A_Z"}
                                  type="radio"
                                  name="radio-option"
                                  onChange={(value) => onChange("sort", "A_Z")}
                                />
                                <span className="radio-checkmark" />
                                <img
                                  src="image/bi_sort-alpha-up.png"
                                  alt=""
                                />{" "}
                                {lang("A to Z")}
                              </label>

                              <label className="radio-container">
                                <input
                                  checked={filter.sort === "Z_A"}
                                  type="radio"
                                  name="radio-option"
                                  onChange={(value) => onChange("sort", "Z_A")}
                                />
                                <span className="radio-checkmark" />
                                <img
                                  src="image/bi_sort-alpha-down.png"
                                  alt=""
                                />{" "}
                                {lang("Z to A")}
                              </label>
                              <label className="radio-container">
                                <input
                                  checked={filter.sort === "most_view"}
                                  type="radio"
                                  name="radio-option"
                                  onChange={(value) =>
                                    onChange("sort", "most_view")
                                  }
                                />
                                <span className="radio-checkmark" />
                                <img
                                  src="image/bi_sort-alpha-down.png"
                                  alt=""
                                />{" "}
                                {lang("Most View")}
                              </label>
                              <label className="radio-container">
                                <input
                                  type="radio"
                                  name="radio-option"
                                  checked={filter.sort === "Price_L_to_H"}
                                  onChange={(value) =>
                                    onChange("sort", "Price_L_to_H")
                                  }
                                />
                                <span className="radio-checkmark" />
                                <img src="image/pricedown.png" alt="" /> 
                                {lang("Price Low to High")}
                              </label>
                              <label className="radio-container">
                                <input
                                  type="radio"
                                  name="radio-option"
                                  checked={filter.sort === "Price_H_to_L"}
                                  onChange={(value) =>
                                    onChange("sort", "Price_H_to_L")
                                  }
                                />
                                <span className="radio-checkmark" />
                                <img src="image/pricelow.png" alt="" />
                                 {lang("Price High to Low")}
                              </label>
                            </div>
                          </div>
                      </div>
                    </Drawer>
                  </div>

                  <div className="result-headding">
                    <h4>{lang("Services")}</h4>
                  </div>
                  <div className="short-by-div">
                    <Select
                      defaultValue={"all"}
                      value={filter.key_filter}
                      className="button-firsttt"
                      options={[
                        {
                          value: "all",
                          label: lang("All"),
                        },
                        {
                          value: "featured",
                          label: lang("Featured"),
                        },
                      ]}
                      onChange={(value) => onChange("key_filter", value)}
                    />
                  </div>
                </div>

                {loading ? (
                      <Loader />
                    ) : (
                      <div className="service_img_main">
                        {services?.map((service) => (
                          <div
                            className="service-img"
                            key={`${service?._id}`}
                          >
                            <div
                              onClick={() =>
                               
                                handleNavProvider(service?.provider_id?._id)
                              }
                            >
                              {service?.service_id?.is_featured && (
                                <div className="featured-type">
                                  {" "}
                                  <h3>{lang("Featured")}</h3>
                                </div>
                              )}
                              <div className="product-img">
                                {" "}
                                <img
                                  src={
                                    service?.cover_image ? service?.cover_image : service?.service_id?.image || notfound
                                  }
                                />
                              </div>
                              <div className="service_text">
                                <h2 style={{ textTransform: "capitalize", textWrap: "pretty" }}> 
                                { service?.name ?
                                  service?.name                               
                                : language !== "en" && language !== null
                                ? service?.service_id?.[`${language}_name`] ?? service?.service_id?.name
                                : service?.service_id?.name 
                                
                                }
                                </h2>
                                <h4>
                                { language !== "en" && language !== null
                                ? service?.provider_id?.[`${language}_name`] ?? service?.provider_id?.name
                                : service?.provider_id?.name }
                                </h4>
                                {/* { language !== "en" && language !== null
                                ? service?.provider_id?.[`${language}_description`] ?? service?.provider_id?.description
                                : service?.provider_id?.description } */}
                                <h3 className="jod-price-disc.">
                                  JOD{" "}
                                  {service?.discount_price ?? service?.discount_price}{" "}
                                  {service?.original_price ? (
                                     service?.discount_price ? <span>{service?.original_price} </span> : service?.price?.original_price 
                                  ) : ( "")}
                                </h3>
                              </div>
                            </div>
                          </div>
                        )
                        )}
                      </div>
                    )}
              </Col>
            </Row>
          </div>
        </section>
        {/* <InviteFriends /> */}
      </>
    </Main>
  );
}

export default ServiceByEvent;
