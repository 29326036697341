import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Rate,
  Row,
  Select,
  Switch,
  Typography,
  Upload,
  message,
} from "antd";
import axios from "axios";
import moment from "moment";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useContext, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import { uploadFile } from "react-s3";
import Prouser from "../../assets/images/user.png";
import AccountSideNav from "../../components/AccountSideNav";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import apiPath from "../../constants/apiPath";
import { useAppContext } from "../../context/AppContext";
import { AuthContext } from "../../context/AuthContext";
import { dateString } from "../../helper/functions";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import StarlayerImg from "../../assets/images/star-layers.png";
import HappyfaceImg from "../../assets/images/happt-face.png";
import starpImg from "../../assets/images/stars_1.png";
import starp1Img from "../../assets/images/stars_2.png";
import lang from "../../helper/langHelper";
import Loader from "../../components/Loader";

window.Buffer = window.Buffer || require("buffer").Buffer;

function Privacy() {
  const { request } = useRequest();

  const { isMobile } = useAppContext();
  const { setIsLoggedIn, setUserProfile, userProfile } =
    useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [profile, setProfile] = useState();
  const [language, setLangauge] = useState("en");
  const [form] = Form.useForm()
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);



  

  const onSubmit = (values) => {
    let payload = { ...values};
    setLoading(true);
    request({
      url: `${apiPath.appRating}`,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          form.resetFields()
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

 

  return (
    <>
     
      
        <div className="account-outers">
          <Row>
            {!isMobile ? <AccountSideNav /> : <BackArrow />}
           {loading ? <Loader/> : <Col span={24} lg={18} xl={15} className="mx-auto mt-auto">
          <Card className="raiting-card">
          <div className="modal-content for-rate-store ">
                <div className="modal-body rate-store-body ">
                  <div className="header-model-maiai">
                    <div className="start-immg-star">
                      <img src={StarlayerImg} alt="" />
                    </div>
                    <div className="happy-face-img">
                      <img src={HappyfaceImg} alt="" />
                    </div>
                    <div className="rateing-ss">
                      <h3>{lang("Rate on Store")}</h3>
                      <p>
                        {lang("If you are enjoy using our app. would you mind rating us on the app store")}{" "}
                      </p>
                    </div>
                    <Form
                     id="create"
                     form={form}
                     onFinish={onSubmit}
                     layout="vertical"
                     initialValues={{
                      rating : 0
                      } }
                    >
                       <Col span={24} xl={24} md={24}>
                       <div className="srtars-mmain-rate">
                              <Form.Item
                                className="username"
                                name="rating"
                              >
                                <Rate  defaultValue={0} />
                              </Form.Item>
                              </div>
                            </Col>
                        <Col span={24} xl={24} md={24}>
                        <div className="coment-main-2">
                              <Form.Item
                                className="username"
                                name="feedback"
                                rules={[
                               
                                  {
                                    required: true,
                                    message: lang("Please enter your feedback!"),
                                  },
                                ]}
                              >
                                <Input.TextArea placeholder={lang("Enter Feedback....")} />
                              </Form.Item>
                              </div>
                            </Col>
                          <Col xl={24} md={24} className="submit-button-maain">
                          <div className="yes-no-butt">
                              <Button className="cancle-rate-btn"   onClick={()=> form.resetFields()}  loading={loading}>
                                {lang("Cancel")}
                              </Button>
                              <Button type="submit" htmlType="submit" loading={loading}>
                                {lang("Submit")}
                              </Button>
                              </div>
                         </Col>
                    </Form>
                 
                  </div>
                </div>
              </div>
          </Card>
            </Col> }
          </Row>
        </div>
 
      <Footer />
    </>
  );
}

export const BackArrow = ({}) => {
  const navigate = useNavigate();
  return (
    <div className="fillter-sidebar">
      <Link onClick={() => navigate(-1)} className="sidebar-btn">
        <ArrowLeftOutlined />
      </Link>
    </div>
  );
};

export default Privacy;
