import React, { useState   } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Main from "../components/layout/Main";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import InviteFriends from "../components/InviteFriends";
import { Container  } from "react-bootstrap";
import { Card , Button } from 'antd';
import BlogImg from '../assets/images/imagefor-blogpage.png';
import ArrowImg from '../assets/images/right-arrow.png';


const { Meta } = Card;

function BlogpageIndex() {
  const navigate = useNavigate();

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };



  return (
    <Main>
      <>
      
      <section>
<Container>
<div className="blog-page-main">
<Card
    hoverable
    style={{
      width: 420,
    
    }}
    cover={<img alt="example" src={BlogImg} />}
  >


<div className="blog_pge-txt">
    <h5>
    Plan The Best. Day. Ever
    </h5>
    <p>
    Thinking of a casual, relaxing day out? Plan something great with
    </p>
    <Button type="primary">Read More <img alt="example" src={ArrowImg} />   </Button>
</div>











  </Card>
  <Card
    hoverable
    style={{
       width: 420,
    
    }}
    cover={<img alt="example" src={BlogImg} />}
  >

<div className="blog_pge-txt">
    <h5>
    Create A Memorable Night for all
    </h5>
    <p>
    Night events can be fun and colorful too. In this blog, we’ll tell you
    </p>
    <Button type="primary">Read More <img alt="example" src={ArrowImg} />   </Button>
</div>

  </Card>
  <Card
    hoverable
    style={{
       width: 420,
    
    }}
    cover={<img alt="example" src={BlogImg} />}
  >

<div className="blog_pge-txt">
    <h5>
    Casual Meetings: The New Norm?
    </h5>
    <p>
    Can’t find a nice venue for your meetings? Worry no more – we have your back
    </p>
    <Button type="primary">Read More <img alt="example" src={ArrowImg} />   </Button>
</div>


  </Card>
  <Card
    hoverable
    style={{
       width: 420,
    
    }}
    cover={<img alt="example" src={BlogImg} />}
  >







<div className="blog_pge-txt">
    <h5>
    Celebrate Music & Art In 2021
    </h5>
    <p>
    This year, plan to celebrate music and art at the biggest music festival.
    </p>
    <Button type="primary">Read More <img alt="example" src={ArrowImg} />   </Button>
</div>





  </Card>
  <Card
    hoverable
    style={{
       width: 420,
    
    }}
    cover={<img alt="example" src={BlogImg} />}
  >


<div className="blog_pge-txt">
    <h5>
    Themed Birthday Parties!
    </h5>
    <p>
    Party themes are the newest norms – pink, purple or black – plan it all!
    </p>
    <Button type="primary">Read More <img alt="example" src={ArrowImg} />   </Button>
</div>





  </Card>
  <Card
    hoverable
    style={{
       width: 420,
    
    }}
    cover={<img alt="example" src={BlogImg} />}
  >


<div className="blog_pge-txt">
    <h5>
    Design Your Perfect Event
    </h5>
    <p>
    Want to plan your perfect event? Here’s a list of everything you need.
    </p>
    <Button type="primary">Read More <img alt="example" src={ArrowImg} />   </Button>
</div>



  </Card>
  <Card
    hoverable
    style={{
       width: 420,
    
    }}
    cover={<img alt="example" src={BlogImg} />}
  >

<div className="blog_pge-txt">
    <h5>
    Create A Memorable Night for all
    </h5>
    <p>
    Night events can be fun and colorful too. In this blog, we’ll tell you
    </p>
    <Button type="primary">Read More <img alt="example" src={ArrowImg} />   </Button>
</div>

  </Card>
  <Card
    hoverable
    style={{
       width: 420,
    
    }}
    cover={<img alt="example" src={BlogImg} />}
  >

<div className="blog_pge-txt">
    <h5>
    Create A Memorable Night for all
    </h5>
    <p>
    Night events can be fun and colorful too. In this blog, we’ll tell you
    </p>
    <Button type="primary">Read More <img alt="example" src={ArrowImg} />   </Button>
</div>

  </Card>
</div>
</Container>
      </section>


        <InviteFriends />
      </>
    </Main>
  );
}

export default BlogpageIndex;
